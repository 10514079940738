import { ELEMENTS } from 'Editor/services/consts';

export class EditorDOMElements {
  // list of default text elements
  static BLOCK_TEXT_ELEMENTS: string[] = [ELEMENTS.ParagraphElement.TAG];

  static BLOCK_FRONTEND_ONLY_ELEMENTS: string[] = [
    ELEMENTS.InvalidElement.TAG,
    ELEMENTS.PasteMarkerElement.TAG,
    ELEMENTS.LoaderElement.TAG,
    'STYLE',
  ];

  static BLOCK_INVALID_ELEMENTS: string[] = [
    ELEMENTS.InvalidElement.TAG,
    ELEMENTS.LoaderElement.TAG,
  ];

  static BLOCK_NON_EDITABLE_ELEMENTS: string[] = [
    ...EditorDOMElements.BLOCK_INVALID_ELEMENTS,
    ELEMENTS.PageBreakElement.TAG, // legacy
    ELEMENTS.SectionBreakElement.TAG, // legacy
    ELEMENTS.TableOfContentsElement.TAG,
    ELEMENTS.ListOfFiguresElement.TAG,
    ELEMENTS.ListOfTablesElement.TAG,
    ELEMENTS.KeywordsElement.TAG,
    ELEMENTS.AuthorsElement.TAG,
  ];

  // list of editable level0 elements
  static EDITABLE_LEVEL0_ELEMENTS: string[] = [
    ELEMENTS.ParagraphElement.TAG,
    ELEMENTS.TableElement.TAG,
    ELEMENTS.FigureElement.TAG,
    ELEMENTS.TrackInsertElement.TAG,
    ELEMENTS.TrackDeleteElement.TAG,
    ELEMENTS.ReferencesSectionElement.TAG,
  ];

  // list of deletable level0 elements
  static DELETABLE_LEVEL0_ELEMENTS: string[] = [
    ...EditorDOMElements.EDITABLE_LEVEL0_ELEMENTS,
    ...EditorDOMElements.BLOCK_NON_EDITABLE_ELEMENTS,
  ];

  public static BLOCK_ELEMENTS: string[] = [
    ...EditorDOMElements.DELETABLE_LEVEL0_ELEMENTS,
    ELEMENTS.ApprovedElement.TAG,
    'READONLY-ELEMENT',
    ELEMENTS.RedactedElement.TAG,
  ];

  static MULTI_BLOCK_CONTAINER_ELEMENTS: string[] = [
    ELEMENTS.ReferencesSectionElement.TAG,
    ELEMENTS.TableCellElement.TAG,
  ];

  static BLOCK_CONTAINER_ELEMENTS: string[] = [
    ...EditorDOMElements.MULTI_BLOCK_CONTAINER_ELEMENTS,
    ELEMENTS.TrackInsertElement.TAG,
    ELEMENTS.TrackDeleteElement.TAG,
  ];

  static FRONTEND_CONTAINER_ELEMENTS: string[] = [
    ELEMENTS.PageElement.TAG,
    ELEMENTS.SectionElement.TAG,
  ];

  // list of splitable level0 elements
  static DEFAULT_SPLITABLE_LEVEL0_ELEMENTS: string[] = [ELEMENTS.ParagraphElement.TAG];

  static INLINE_FRONTEND_ONLY_ELEMENTS: string[] = [
    'BR',
    ELEMENTS.PasteMarkerElement.TAG,
    ELEMENTS.TableElement.ELEMENTS.TABLE_HEADER.TAG,
    ELEMENTS.TableElement.ELEMENTS.TABLE_HEADER_CELL.TAG,
    ELEMENTS.InvalidElement.TAG,
    'STYLE',
    'SPAN',
    'DIV',
  ];

  // list of non selectable elements
  static INLINE_NON_SELECTABLE_ELEMENTS: string[] = [
    'STYLE',
    'BR',
    ELEMENTS.TrackDeleteElement.TAG,
    ELEMENTS.EquationElement.TAG,
    ELEMENTS.PasteMarkerElement.TAG,
    'SPAN',
    // 'DIV',
    ELEMENTS.PlaceholderElement.TAG,
    ELEMENTS.PageBreakElement.TAG,
    ELEMENTS.SectionBreakElement.TAG,
    ELEMENTS.ColumnBreakElement.TAG,
    // 'FIELD-ELEMENT',
  ];

  // list of non-editable elements that can be inside a text element
  static INLINE_NON_EDITABLE_ELEMENTS: string[] = [
    ELEMENTS.CitationsGroupElement.TAG,
    // 'CITATION-ELEMENT',
    ELEMENTS.NoteElement.TAG,
    ELEMENTS.SymbolElement.TAG,
    ELEMENTS.PasteMarkerElement.TAG,
    ELEMENTS.EquationElement.TAG,
    ELEMENTS.InvalidElement.TAG,
    ELEMENTS.PlaceholderElement.TAG,
    ELEMENTS.PageBreakElement.TAG,
    ELEMENTS.SectionBreakElement.TAG,
    ELEMENTS.ColumnBreakElement.TAG,
    ELEMENTS.TabElement.TAG,
    ELEMENTS.ImageElement.TAG,
    // 'FIELD-ELEMENT',
  ];

  // list of elements that can't have styles inside
  static INLINE_NON_STYLABLE_ELEMENTS: string[] = [
    ELEMENTS.CitationsGroupElement.TAG,
    ELEMENTS.NoteElement.TAG,
    ELEMENTS.EquationElement.TAG,
    // ELEMENTS.FieldElement.TAG,
    ELEMENTS.PlaceholderElement.TAG,
    ELEMENTS.PageBreakElement.TAG,
    ELEMENTS.SectionBreakElement.TAG,
    ELEMENTS.ColumnBreakElement.TAG,
    ELEMENTS.TabElement.TAG,
  ];

  static INLINE_TEXT_ELEMENTS: string[] = [ELEMENTS.FormatElement.TAG];

  static INLINE_WRAP_ELEMENTS: string[] = [
    ELEMENTS.CommentElement.TAG,
    ELEMENTS.TemporaryComment.TAG,
    ELEMENTS.HyperlinkElement.TAG,
    ELEMENTS.FieldElement.TAG,
  ];

  static INLINE_EDITABLE_ELEMENTS: string[] = [
    ...EditorDOMElements.INLINE_TEXT_ELEMENTS,
    ...EditorDOMElements.INLINE_WRAP_ELEMENTS,
  ];

  static INLINE_ELEMENTS: string[] = [
    ...EditorDOMElements.INLINE_EDITABLE_ELEMENTS,
    ...EditorDOMElements.INLINE_NON_EDITABLE_ELEMENTS,
  ];

  static INLINE_DOUBLE_STATE_ELEMENTS: string[] = [
    ELEMENTS.PlaceholderElement.TAG,
    ELEMENTS.PageBreakElement.TAG,
    ELEMENTS.SectionBreakElement.TAG,
    ELEMENTS.ColumnBreakElement.TAG,
  ];

  static INLINE_LAST_CHILD_ELEMENTS: string[] = [
    ELEMENTS.PageBreakElement.TAG,
    ELEMENTS.SectionBreakElement.TAG,
    ELEMENTS.ColumnBreakElement.TAG,
  ];

  static ALLOWED_CHILDREN_TO_PARSE: string[] = [
    ELEMENTS.FormatElement.TAG,
    ELEMENTS.CommentElement.TAG,
    ELEMENTS.TemporaryComment.TAG,
    ELEMENTS.HyperlinkElement.TAG,
    ELEMENTS.CitationsGroupElement.TAG,
    ELEMENTS.CitationElement.TAG,
    ELEMENTS.NoteElement.TAG,
    ELEMENTS.SymbolElement.TAG,
    ELEMENTS.CrossReferenceElement.TAG,
    ELEMENTS.EquationElement.TAG,
    ELEMENTS.TrackDeleteElement.TAG,
    ELEMENTS.TrackInsertElement.TAG,
    'CAPTION',
    ELEMENTS.TableBodyElement.TAG,
    ELEMENTS.TableRowElement.TAG,
    ELEMENTS.TableCellElement.TAG,
    ELEMENTS.ImageElement.TAG,
    'IMG',
    'FIGCAPTION',
    ELEMENTS.ParagraphElement.TAG,
    ELEMENTS.TableElement.TAG,
    ELEMENTS.FigureElement.TAG,
    ELEMENTS.FieldElement.TAG,
    ELEMENTS.PageBreakElement.TAG,
    ELEMENTS.SectionBreakElement.TAG,
    ELEMENTS.ColumnBreakElement.TAG,
    ELEMENTS.TableOfContentsElement.TAG,
    ELEMENTS.ListOfFiguresElement.TAG,
    ELEMENTS.ListOfTablesElement.TAG,
    ELEMENTS.KeywordsElement.TAG,
    ELEMENTS.AuthorsElement.TAG,
    ELEMENTS.PlaceholderElement.TAG,
    ELEMENTS.TabElement.TAG,
    ELEMENTS.ReferencesSectionElement.TAG,
  ];

  static WRAPPER_LEVEL0_ELEMENTS: string[] = ['READONLY-ELEMENT', ELEMENTS.ApprovedElement.TAG];

  static isTableElement(node: Node | null): node is Editor.Elements.TableElement {
    return (
      node != null &&
      node.nodeName === ELEMENTS.TableElement.TAG &&
      'identifier' in node &&
      node.identifier === ELEMENTS.TableElement.IDENTIFIER
    );
  }

  static isTableCellElement(node: Node | null): node is Editor.Elements.TableCellElement {
    return (
      node != null &&
      node.nodeName === ELEMENTS.TableCellElement.TAG &&
      'identifier' in node &&
      node.identifier === ELEMENTS.TableCellElement.IDENTIFIER
    );
  }

  static isFigureElement(node: Node | null): node is Editor.Elements.FigureElement {
    return node != null && node.nodeName === ELEMENTS.FigureElement.TAG;
  }

  static isImageElement(node: Node | null): node is Editor.Elements.ImageElement {
    return node != null && node.nodeName === ELEMENTS.ImageElement.TAG;
  }

  static isEquationElement(node: Node | null): node is Editor.Elements.EquationElement {
    return node != null && node.nodeName === ELEMENTS.EquationElement.TAG;
  }

  static isPageElement(node: Node | null): node is Editor.Elements.PageElement {
    return node != null && node.nodeName === ELEMENTS.PageElement.TAG;
  }

  static isSectionElement(node: Node | null): node is Editor.Elements.SectionElement {
    return node != null && node.nodeName === ELEMENTS.SectionElement.TAG;
  }

  static isParagraphElement(node: Node | null): node is Editor.Elements.ParagraphElement {
    return node != null && node.nodeName === ELEMENTS.ParagraphElement.TAG;
  }

  static isFormatElement(node: Node | null): node is Editor.Elements.FormatElement {
    return node != null && node.nodeName === ELEMENTS.FormatElement.TAG;
  }

  static isFieldElement(node: Node | null): node is Editor.Elements.FieldElement {
    return node != null && node.nodeName === ELEMENTS.FieldElement.TAG;
  }

  static isNoteElement(node: Node | null): node is Editor.Elements.NoteElement {
    return node != null && node.nodeName === ELEMENTS.NoteElement.TAG;
  }

  static isTrackInsertElement(node: Node | null): node is Editor.Elements.TrackInsertElement {
    return node != null && node.nodeName === ELEMENTS.TrackInsertElement.TAG;
  }

  static isTrackDeleteElement(node: Node | null): node is Editor.Elements.TrackDeleteElement {
    return node != null && node.nodeName === ELEMENTS.TrackDeleteElement.TAG;
  }

  static isHyperlinkElement(node: Node | null): node is Editor.Elements.HyperlinkElement {
    return node != null && node.nodeName === ELEMENTS.HyperlinkElement.TAG;
  }

  static isTableOfContentsElement(
    node: Node | null,
  ): node is Editor.Elements.TableOfContentsElement {
    return node != null && node.nodeName === ELEMENTS.TableOfContentsElement.TAG;
  }

  static isSymbolElement(node: Node | null): node is Editor.Elements.SymbolElement {
    return node != null && node.nodeName === ELEMENTS.SymbolElement.TAG;
  }

  static isListOfFiguresElement(node: Node | null): node is Editor.Elements.ListOfFiguresElement {
    return node != null && node.nodeName === ELEMENTS.ListOfFiguresElement.TAG;
  }

  static isAuthorsElement(node: Node | null): node is Editor.Elements.AuthorsElement {
    return node != null && node.nodeName === ELEMENTS.AuthorsElement.TAG;
  }

  static isKeywordsElement(node: Node | null): node is Editor.Elements.KeywordsElement {
    return node != null && node.nodeName === ELEMENTS.KeywordsElement.TAG;
  }

  static isTrackedElement(
    node: Node | null,
  ): node is Editor.Elements.TrackInsertElement | Editor.Elements.TrackDeleteElement {
    return (
      EditorDOMElements.isTrackInsertElement(node) || EditorDOMElements.isTrackDeleteElement(node)
    );
  }

  static isCitationsGroupElement(node: Node | null): node is Editor.Elements.CitationsGroupElement {
    return node != null && node.nodeName === ELEMENTS.CitationsGroupElement.TAG;
  }

  static isSupportedElement(node: Node | null): node is Editor.Elements.SupportedElement {
    return (
      node != null &&
      (EditorDOMElements.BLOCK_ELEMENTS.includes(node.nodeName) ||
        EditorDOMElements.INLINE_ELEMENTS.includes(node.nodeName) ||
        node.nodeName === ELEMENTS.TableCellElement.TAG)
    );
  }

  static isSupportedBlockElement(node: Node | null): node is Editor.Elements.SupportedBlockElement {
    return node != null && EditorDOMElements.BLOCK_ELEMENTS.includes(node.nodeName);
  }

  static isNodeBlockWrapperElement(node: Node | null): node is Editor.Elements.BaseBlockElement {
    if (node && EditorDOMElements.WRAPPER_LEVEL0_ELEMENTS.includes(node.nodeName)) {
      return true;
    }

    return false;
  }

  static isApprovedElement(node: Node | null): node is Editor.Elements.ApprovedViewElement {
    if (node && node.nodeName === ELEMENTS.ApprovedElement.TAG) {
      return true;
    }

    return false;
  }

  static isNodeContainerElement(
    node: Node | null,
  ): node is
    | Editor.Elements.BaseBlockElement
    | Editor.Elements.TrackInsertElement
    | Editor.Elements.TrackDeleteElement {
    if (!node) {
      return false;
    }
    if (!EditorDOMElements.BLOCK_CONTAINER_ELEMENTS.includes(node.nodeName)) {
      return false;
    }

    if (!EditorDOMElements.isTrackedElement(node)) {
      return true;
    }

    if (node.displayType === 'BLOCK') {
      return true;
    }

    return false;
  }

  static isNodeBlockTextElement(node: Node | null): node is Editor.Elements.BlockTextElements {
    if (node && EditorDOMElements.BLOCK_TEXT_ELEMENTS.includes(node.nodeName)) {
      return true;
    }

    return false;
  }

  static isNodeInlineElement(node: Node | null): node is Editor.Elements.BaseViewElement | Text {
    if (node instanceof Text) {
      return true;
    }

    if (node instanceof Element && EditorDOMElements.INLINE_ELEMENTS.includes(node.nodeName)) {
      return true;
    }

    return EditorDOMElements.isInlineNode(node);
  }

  static isNodeInlineFrontendElement(node: Node | null): boolean {
    return !!(node && EditorDOMElements.INLINE_FRONTEND_ONLY_ELEMENTS.includes(node.nodeName));
  }

  static isNodeSuggestionParagraphMarker(
    node: Node | null,
  ): node is Editor.Elements.TrackInsertElement | Editor.Elements.TrackDeleteElement {
    return node != null && EditorDOMElements.isTrackedElement(node) && !node.isMergeable();
  }

  static isNodeHyperlinkElement(node: Node | null): node is Editor.Elements.HyperlinkElement {
    if (node && node.nodeName === ELEMENTS.HyperlinkElement.TAG) {
      return true;
    }

    return false;
  }

  static isInlineNode(node: Node | null) {
    if (node instanceof Text) {
      return true;
    }

    if (!(node instanceof Element)) {
      return false;
    }

    if (document.body.contains(node)) {
      const gcs = 'getComputedStyle' in window;
      // @ts-expect-error
      const cStyle = (gcs ? window.getComputedStyle(node, '') : node.currentStyle).display;
      if (cStyle.includes('inline')) {
        return true;
      }
    } else {
      const gcs = 'getComputedStyle' in window;
      const t = document.createElement(node.nodeName);
      document.body.appendChild(t);
      // @ts-expect-error
      const cStyle = (gcs ? window.getComputedStyle(t, '') : t.currentStyle).display;
      document.body.removeChild(t);
      if (cStyle.includes('inline')) {
        return true;
      }
    }

    return false;
  }
}
