type SelectionComponentProp = {
  collapsed?: boolean;
  start: {
    b: string; // block id
    p: Editor.Selection.Path; // path to element
  };
  end: {
    b: string; // block id
    p: Editor.Selection.Path; // path to element
  };
}[];

type SelectionComponent = {
  b?: SelectionComponentProp;
  a?: SelectionComponentProp;
};

type PatchComponent = {
  op: Realtime.Core.RealtimeOps;
  doc: Realtime.Core.RealtimeObject;
};

export class Patch {
  id: string;
  components: PatchComponent[] = [];
  t: { created: number };
  selection?: SelectionComponent;

  constructor() {
    this.t = {
      created: Date.now(),
    };
    this.id = this.t.created.toString();
  }

  get isEmpty() {
    return this.components.length <= 0;
  }

  get size() {
    return this.components.length;
  }

  getLastComponent() {
    return this.components[this.components.length - 1];
  }

  add(doc: Realtime.Core.RealtimeObject, op: Realtime.Core.RealtimeOps) {
    if (op.length <= 0 || doc.model.type == null) {
      return false;
    }
    if (this.components.length > 0 && this.components[this.components.length - 1].doc === doc) {
      const compose = doc.model.type.compose;
      this.components[this.components.length - 1].op = compose(
        this.components[this.components.length - 1].op,
        op,
      );
      return true;
    }
    this.components.push({
      doc,
      op,
    });
    return false;
  }

  addSelectionInfo(before?: SelectionComponentProp | null, after?: SelectionComponentProp | null) {
    this.selection = {
      a: after || this.selection?.a,
      b: before || this.selection?.b,
    };
  }

  transformPatch(
    doc: Realtime.Core.RealtimeObject,
    ops: Realtime.Core.RealtimeOps,
  ): Realtime.Core.RealtimeOps {
    if (!doc.model.type) {
      return ops;
    }

    const transform = doc.model.type.transform;
    const transformX = doc.model.type.transformX;
    const isNoop = doc.model.type.isNoop;

    let workingOps: Realtime.Core.RealtimeOps = ops;
    const newList: PatchComponent[] = [];
    for (let i = this.components.length - 1; i >= 0; --i) {
      let item = this.components[i];
      if (item.doc !== doc) {
        newList.push(item);
        continue;
      }
      let stackOp = item.op;
      let transformedStackOp;
      let transformedOp;

      if (transformX) {
        let result = transformX(workingOps, stackOp);
        transformedOp = result[0];
        transformedStackOp = result[1];
      } else {
        transformedOp = transform(workingOps, stackOp, 'left');
        transformedStackOp = transform(stackOp, workingOps, 'right');
      }
      if (!isNoop || !isNoop(transformedStackOp)) {
        if (transformedStackOp.length > 0) {
          newList.push({
            doc,
            op: transformedStackOp,
          });
        }
      }
      workingOps = transformedOp;
    }
    this.components = newList.reverse();
    return workingOps;
  }

  async revert() {
    const successfullRevert: PatchComponent[] = [];
    try {
      for (let index = this.components.length - 1; index >= 0; index--) {
        await this.components[index].doc.revert(this.components[index].op, {
          source: 'UNDO',
        });
        successfullRevert.push(this.components[index]);
      }
    } catch (error) {
      // revert 'reverted' ops per component
      for (let index = 0; index < successfullRevert.length; index++) {
        await successfullRevert[index].doc.apply(successfullRevert[index].op, {
          source: 'REDO',
        });
      }
      throw error;
    }
  }

  async apply() {
    const successfullApply: PatchComponent[] = [];
    try {
      for (let index = 0; index < this.components.length; index++) {
        await this.components[index].doc.apply(this.components[index].op, {
          source: 'REDO',
        });
        successfullApply.push(this.components[index]);
      }
    } catch (error) {
      // revert 'applied' ops per component
      for (let index = successfullApply.length - 1; index >= 0; index--) {
        await successfullApply[index].doc.revert(successfullApply[index].op, {
          source: 'UNDO',
        });
      }
      throw error;
    }
  }

  composeWithPatch(patch?: Patch) {
    if (this.isEmpty || !patch || patch?.isEmpty) {
      return false;
    }
    if (patch.size > 1 || this.getLastComponent().doc !== patch.getLastComponent().doc) {
      return false;
    }
    const components = patch.components;
    for (let index = 0; index < components.length; index++) {
      const component = components[index];
      this.add(component.doc, component.op);
    }
    this.addSelectionInfo(this.selection?.b, patch.selection?.a);
    return true;
  }
}
