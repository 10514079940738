import BaseController from '../BaseController';
import { IndexerDeltaType } from '../Models/ModelIndexer';
import { CommentList } from './CommentList';
import { Logger } from '_common/services';
import { Descendant } from 'slate';

type CommentsControllerArgType = Pick<Editor.Data.State, 'transport' | 'models' | 'context'>;

export class CommentsController extends BaseController {
  private commentsList?: CommentList;
  constructor(Data: CommentsControllerArgType) {
    super(Data);
    this.handleCommentListChanged = this.handleCommentListChanged.bind(this);
    this.handleCommentListError = this.handleCommentListError.bind(this);
    this.handleCommentChanged = this.handleCommentChanged.bind(this);
  }

  start(documentId: string): void {
    this.commentsList = this.Data.models?.getIndexer(
      this.Data.models.TYPE_NAME.COMMENT,
    ) as CommentList;
    this.commentsList?.on('ERROR', this.handleCommentListError);
    this.commentsList?.on('CHANGED_DELTA', this.handleCommentListChanged);
    this.commentsList?.start(documentId);
  }

  private handleCommentListChanged(data: IndexerDeltaType<any>) {
    const dataObjs = this.commentsList?.data || {};
    const commentListId = Object.keys(dataObjs);
    this.getNodePermissionsForComments(commentListId);
    if (data.in.comments.length > 0) {
      for (let index = 0; index < data.in.comments.length; index++) {
        const element = dataObjs[data.in.comments[index]];
        element.on('LOADED', this.handleCommentChanged);
        element.on('UPDATED', this.handleCommentChanged);
      }
    }

    if (data.in.temp.length > 0) {
      this.Data.events?.emit('OPEN_TEMPORARY_COMMENT', data.in.temp);
    }

    if (data.out.temp.length > 0) {
      this.Data.events?.emit('CANCEL_TEMPORARY_COMMENT');
    }
    this.loadCommentsData();
  }

  private handleCommentListError(error: Error) {
    Logger.error(error);
  }

  private handleCommentChanged(data: Comments.CommentData | null) {
    this.Data.events?.emit('UPDATE_COMMENT', data);
  }

  private loadCommentsData() {
    const commentsData: { [index: string]: Comments.CommentData } = {};
    const commentsList = this.commentsList?.list || [];
    const dataObjs = this.commentsList?.data || {};
    for (let index = 0; index < commentsList.length; index++) {
      commentsData[commentsList[index]] = dataObjs[commentsList[index]].get([]) || {
        id: commentsList[index],
        comments: [],
        content: '',
      };
    }
    this.Data.events?.emit('LOAD_COMMENTS_DATA', commentsList, commentsData);
  }

  get list() {
    return this.commentsList;
  }

  stop(): void {}

  destroy(): void {}

  get(commentId: string) {
    return this.Data.models?.get(this.Data.models.TYPE_NAME.COMMENT, commentId);
  }

  getCommentLocations(commentId: string) {
    if (this.commentsList?.isTempComment(commentId)) {
      return this.commentsList?.tempCommentLocation(commentId);
    }
    return this.commentsList?.commentLocation(commentId);
  }

  async editComment(commentId: string, content: Descendant[]) {
    const comment = this.get(commentId);
    if (comment && comment.id !== '') {
      return comment.editComment(content);
    }
  }

  async replyComment(commentId: string, content: Descendant[]) {
    const comment = this.get(commentId);
    if (comment && comment.id !== '') {
      return comment.replyComment(content);
    }
  }

  async voteComment(commentId: string, vote: boolean) {
    const comment = this.get(commentId);
    if (comment && comment.id !== '') {
      return comment.voteComment(vote);
    }
  }

  async editReply(commentId: string, replyId: string, content: Descendant[]) {
    const comment = this.get(commentId);
    if (comment && comment.id !== '') {
      return comment.editReply(replyId, content);
    }
  }

  async deleteReply(commentId: string, replyId: string) {
    const comment = this.get(commentId);
    if (comment && comment.id !== '') {
      return comment.deleteReply(replyId);
    }
  }

  async voteReply(commentId: string, replyId: string, vote: boolean) {
    const comment = this.get(commentId);
    if (comment && comment.id !== '') {
      return comment.voteReply(replyId, vote);
    }
  }

  async changeCommentPriority(commentId: string, priority: Comments.CommentPriority) {
    const comment = this.get(commentId);
    if (comment && comment.id !== '') {
      return comment.changeCommentPriority(priority);
    }
  }

  async resolveComment(commentId: string) {
    this.Data.models?.undoManager?.clearUndoRedo();
    const comment = this.get(commentId);
    if (comment && comment.id !== '') {
      await comment.resolveComment();
    }
  }

  async deleteComment(commentId: string) {
    this.Data.models?.undoManager?.clearUndoRedo();
    const comment = this.get(commentId);
    if (comment && comment.id !== '') {
      await comment.deleteComment();
    }
  }

  getNodePermissionsForComments(commentIds: string[]): Promise<void> {
    return new Promise((resolve) => {
      this.Data.transport.dispatchEvent(
        'COMMENT:ALL:NODES:PERMISSIONS',
        {
          commentIds,
        },
        (response: Realtime.Transport.RealtimeResponse) => {
          const permissions = response.payload;
          const length = commentIds.length;
          if (permissions) {
            // eslint-disable-next-line
            for (let i = 0; i < length; i++) {
              let commentId = commentIds[i];
              if (permissions[commentId]) {
                const comment = this.get(commentId);
                if (comment && comment.id !== '') {
                  comment.nodesPermissions = permissions[commentId];
                }
              }
            }
          }
          resolve();
        },
      );
    });
  }

  deleteAllComments(commentIds: string[] = []): Promise<void> {
    this.Data.models?.undoManager?.clearUndoRedo();
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'COMMENT:ALL:DELETE',
        {
          commentIds: commentIds,
        },
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  resolveAllComments(commentIds: string[] = []): Promise<void> {
    this.Data.models?.undoManager?.clearUndoRedo();
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'COMMENT:ALL:RESOLVE',
        {
          commentIds: commentIds,
        },
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  addComment(content: Descendant[], tempReference: any) {
    this.Data.models?.undoManager?.clearUndoRedo();
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'COMMENT:ADD',
        {
          comment: {
            content,
            location: [],
            priority: 'MEDIUM',
          },
          tempReference,
        },
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve({
              id: response.payload?.commentId,
            });
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  cancelTemporaryComment(tempCommentReference: string) {
    this.Data.models?.undoManager?.clearUndoRedo();
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'COMMENT:CANCEL:TEMP',
        {
          tempCommentReference,
        },
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve(response.payload?.tempReference);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  addTemporaryComment(tempCommentReference: string, ranges: Editor.Selection.RangeData[]) {
    this.Data.models?.undoManager?.clearUndoRedo();
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'COMMENT:TEMP:ADD',
        {
          tempCommentReference,
          ranges,
        },
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve(response.payload?.tempReference);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }
}
