/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable class-methods-use-this */
import { isEqual } from 'lodash';

import { BaseTypedEmitter } from '_common/services/Realtime';
import { store } from '_common/redux';

import { updateData, citationsListed, selectIsIEnvision } from 'App/redux/appSlice';
import {
  openModal,
  openAndUpdateModal,
  closeAndResetModal,
  closeAllModals,
} from '_common/modals/ModalsSlice';

import { setFontValidationData } from 'Editor/redux/FontsSlice';
import {
  setSelectedText,
  FindAndReplaceSliceState,
  setCurrentResult,
} from 'Editor/redux/FindAndReplaceSlice';
import { updateToolbarValues as updateToolbarValuesRedux } from 'Editor/redux/ToolbarSlice';

import { loadListStyles } from 'Editor/redux/listStylesSlice';
import { addNote, loadNotes, setNoteOverlayData, updateNote } from 'Editor/redux/NotesSlice';
import { Modals } from '_common/modals/ModalsSliceTypes';
import { toggleLanguageTrigger } from 'Editor/pages/EditorPage/Sidepanel/SpellCheckTab/SpellcheckSlice';
import { loadToc } from 'Editor/redux/TocSlice';
import { loadStyles } from 'Editor/redux/StylesSlice';
import {
  loadDeletedTasks,
  loadTasks,
  setTaskOverlayData,
  updateTask,
} from 'Editor/redux/TasksSlice';
import {
  cleanPermissionsState,
  deselectAllPermissions,
  loadedPermissions,
} from 'Editor/redux/PermissionsSlice';
import {
  getTrackingState,
  setDocumentTracking,
  toggleDocumentTracking,
} from 'Editor/redux/TrackingSlice';
import {
  loadTrackedActions,
  selectFilteredTrackedActions,
  updateTrackedActions,
} from 'Editor/redux/TrackedActionsSlice';
import {
  addTemporaryComment,
  cancelTemporaryComment,
  CommentsSliceState,
  loadedComments,
  selectFilteredComments,
  updateComment,
} from 'Editor/redux/CommentsSlice';
import { setSidebarView } from 'Editor/redux/SidebarSlice';

import {
  getDocumentObject,
  selectReadOnlyMode,
  setEditableValue,
  setVisibleValue,
  setSelection,
  setLoadingValue,
  setParagraphsLoadedValue,
  setLoadedVersion,
  setSelectedStyle,
  setVersionHistoryValue,
  setReadOnlyValue,
  setUsersOnline,
  addUserOnline,
  removeUserOnline,
  setLayout,
  getDocumentInfo,
  updateZoomValue,
  setCanUndo,
  setCanRedo,
  setBlockWindow,
} from 'Editor/redux/EditorStatusSlice';
import { loadSectionProperties } from 'Editor/redux/SectionsSlice';
import { loadNodesForApproval, cleanBlockApprovalState } from 'Editor/redux/BlockApprovalSlice';
import {
  citationsLoaded,
  setSelectedCitation,
  referenceStylesLoaded,
  setReferenceStyleId,
} from 'Editor/redux/CitationsSlice';
import { setPasteOptionsData } from 'Editor/redux/PasteOptionsSlice';
import { saveImage } from 'Editor/redux/ImageUploadSlice';
import { loadLanguageData } from 'Editor/redux/EditorLanguageSlice';
import { completeAction, setPulseData } from 'App/redux/onboardingSlice';
import { setWordsQuantity } from 'Editor/redux/wordCountSlice';

export default class ReduxInterface extends BaseTypedEmitter {
  //                        gets from state
  // ######################################################
  static getStore() {
    return store;
  }

  static getFilteredComments() {
    return selectFilteredComments(store.getState())?.order;
  }

  static getFilteredSuggestions() {
    return selectFilteredTrackedActions(store.getState())?.order;
  }

  static isReadonly(): boolean {
    return selectReadOnlyMode(store.getState());
  }

  static getEditorState() {
    return store.getState().editor;
  }

  static getPlatformInfo() {
    return store.getState().app.platform;
  }

  static getSidebarState() {
    return store.getState().editor.sidebar;
  }

  static getLoggedUserDocumentPermissions() {
    const state = store.getState();
    const document = getDocumentObject(state);
    if (document) {
      return document.user_permissions;
    }
    return null;
  }

  static getLocale() {
    return store.getState().intl.locale;
  }

  static getCurrentUser() {
    return store.getState().auth.userId;
  }

  static getDocumentObject() {
    const state = store.getState();
    return getDocumentObject(state);
  }

  static getCurrentBrowser() {
    return store.getState().app.platform.browser;
  }

  static getSelectedTask() {
    return store.getState().editor.tasks.overlay.selected;
  }

  static getCommentsState() {
    return store.getState().editor.comments;
  }

  static getTrackedActionsState() {
    return store.getState().editor.trackedActions;
  }

  static getBlockApprovalsState() {
    return store.getState().editor.blockApproval;
  }

  static getEditorTrackingState() {
    return getTrackingState(store.getState());
  }

  static isEditorTrackingStateOn() {
    return getTrackingState(store.getState()).state;
  }

  static getEditorLoadingState() {
    return store.getState().editor.status.loading;
  }

  static getToolbarStylesStatus() {
    const styles: any = {
      bold: store.getState().editor.toolbar.bold,
      italic: store.getState().editor.toolbar.italic,
      underline: store.getState().editor.toolbar.underline,
    };
    return styles;
  }

  static getZoomValue() {
    return store.getState().editor.status.zoom;
  }

  // ######################################################
  //                        actions
  // ######################################################
  static dispatch(type: string, payload: any) {
    store.dispatch({ type, payload });
  }

  static setReadonlyState(payload: Parameters<typeof setReadOnlyValue>[0]) {
    store.dispatch(setReadOnlyValue(payload));
  }

  static openVersionHistory() {
    store.dispatch(setVersionHistoryValue(true));
  }

  static toggleTracking(payload: Parameters<typeof toggleDocumentTracking>[0]) {
    const currentTrackingState = getTrackingState(store.getState());

    if (currentTrackingState.lock === false) {
      store.dispatch(toggleDocumentTracking(payload));
    }
  }

  static setTracking(payload: Parameters<typeof setDocumentTracking>[0]) {
    store.dispatch(setDocumentTracking(payload));
  }

  static usersOnline(payload: Parameters<typeof setUsersOnline>[0]) {
    store.dispatch(setUsersOnline(payload));
  }

  static userJoined(payload: Parameters<typeof addUserOnline>[0]) {
    store.dispatch(addUserOnline(payload));
  }

  static userLeft(payload: Parameters<typeof removeUserOnline>[0]) {
    store.dispatch(removeUserOnline(payload));
  }

  static setLayout(payload: Parameters<typeof setLayout>[0]) {
    store.dispatch(setLayout(payload));
  }

  static setPasteOptionsData(payload: Parameters<typeof setPasteOptionsData>[0]) {
    store.dispatch(setPasteOptionsData(payload));
  }

  static setLoadVersion(payload: Parameters<typeof setLoadedVersion>[0]) {
    store.dispatch(setLoadedVersion(payload));
  }

  static setParagraphsLoaded(payload: Parameters<typeof setParagraphsLoadedValue>[0]) {
    store.dispatch(setParagraphsLoadedValue(payload));
  }

  static deselectAllNodeForPermissions() {
    store.dispatch(deselectAllPermissions());
  }

  static cleanBlockApprovalState() {
    store.dispatch(cleanBlockApprovalState());
  }

  static updateDocumentData(documentData: Realtime.Core.Document.Data) {
    store.dispatch(updateData({ objectId: documentData._id, data: documentData }));
  }

  static commentsLoad(order: CommentsSliceState['order'], comments: Comments.CommentsData) {
    //@ts-expect-error editor.Comment should be import('Editor/services').CommentData, but CommentData its not 100%
    store.dispatch(loadedComments({ order, comments }));
  }

  static suggestionsLoad(order: any, data: any) {
    const dataObj: any = {};
    const keys = Object.keys(data);

    for (let index = 0; index < keys.length; index++) {
      const element = data[keys[index]];
      dataObj[element.id] = element;
    }

    store.dispatch(loadTrackedActions({ order, byId: dataObj }));
  }

  static loadSectionProperties(payload: any) {
    store.dispatch(loadSectionProperties(payload));
  }

  static setEditable(editable: Parameters<typeof setEditableValue>[0]) {
    store.dispatch(setEditableValue(editable));
  }

  static setVisible(visible: Parameters<typeof setVisibleValue>[0]) {
    store.dispatch(setVisibleValue(visible));
  }

  static updateComment(payload: Parameters<typeof updateComment>[0]) {
    store.dispatch(updateComment(payload));
  }

  static openAndUpdateModal(payload: Parameters<typeof openAndUpdateModal>[0]) {
    store.dispatch(openAndUpdateModal(payload));
  }

  static openModal(payload: Parameters<typeof openModal>[0]) {
    store.dispatch(openModal(payload));
  }

  static closeAndResetModal(payload: Parameters<typeof closeAndResetModal>[0]) {
    store.dispatch(closeAndResetModal(payload));
  }

  static setEditorError(title: string, message: string, code: string, status?: number) {
    store.dispatch(closeAllModals());
    store.dispatch(
      openAndUpdateModal({
        modal: 'EditorErrorModal',
        data: {
          code,
          title,
          message,
          status,
        },
      }),
    );
    ReduxInterface.stopEditorLoading();
  }

  static clearEditorError() {
    store.dispatch(closeAndResetModal('EditorErrorModal'));
  }

  static updateTrackedActions(trackedActions: Editor.TrackedAction[]) {
    store.dispatch(updateTrackedActions(trackedActions));
  }

  static updateToolbarValues(values: Parameters<typeof updateToolbarValuesRedux>[0]) {
    const state = store.getState().editor.toolbar;
    const someValues = (Object.keys(values) as Array<keyof typeof values>).some(
      (key) => state[key] !== values[key],
    );
    if (someValues) {
      return store.dispatch(updateToolbarValuesRedux(values));
    }
    return null;
  }

  static startEditorLoading(payload?: Parameters<typeof setLoadingValue>[0]) {
    if (store.getState().editor.status.loading !== payload) {
      if (payload) {
        store.dispatch(setLoadingValue(payload));
      } else {
        store.dispatch(setLoadingValue(true));
      }
    }
  }

  static stopEditorLoading() {
    if (store.getState().editor.status.loading) {
      store.dispatch(setLoadingValue(false));
    }
  }

  static setSelectionStatus(payload: Parameters<typeof setSelection>[0]) {
    const state = store.getState().editor.status.selection;
    if (
      (Object.keys(payload) as Array<keyof typeof payload>).some(
        (key) => !isEqual(payload[key], state[key]),
      )
    ) {
      store.dispatch(setSelection(payload));
    }
  }

  static startRevertingVersion() {
    store.dispatch({
      type: 'editor/START_REVERTING_VERSION',
    });
  }

  static loadTableOfContents(list: any, data: any) {
    store.dispatch(loadToc({ list, data }));
  }

  static loadNotes(notes: any) {
    store.dispatch(loadNotes({ ...notes }));
  }

  static updateNote(note: any) {
    store.dispatch(updateNote(note));
  }

  static addNote(note: any) {
    store.dispatch(addNote(note));
  }

  static setNoteOverlayData(action: any) {
    store.dispatch(setNoteOverlayData(action));
  }

  static openTemporaryCommentCard(payload: Parameters<typeof addTemporaryComment>[0]) {
    store.dispatch(addTemporaryComment(payload));
  }

  static cancelTemporaryComment() {
    store.dispatch(cancelTemporaryComment());
  }

  static documentStylesLoad(styles: Parameters<typeof loadStyles>[0]) {
    return store.dispatch(loadStyles(styles));
  }

  static setSelectedDocumentStyle(style: Parameters<typeof setSelectedStyle>[0]) {
    const selectedStyle = store.getState().editor.status.selectedStyle;
    return !isEqual(selectedStyle, style) && store.dispatch(setSelectedStyle(style));
  }

  static setFontValidationData(payload: Parameters<typeof setFontValidationData>[0]) {
    return store.dispatch(setFontValidationData(payload));
  }

  static setSidebarView(view: Parameters<typeof setSidebarView>[0]) {
    return store.dispatch(setSidebarView(view));
  }

  static updateDocumentStatus(
    documentId: string,
    statusId: Realtime.Core.Document.Data['status'],
    statusInfo: Realtime.Core.Document.Data['statusInfo'],
  ) {
    return store.dispatch(
      updateData({ objectId: documentId, data: { status: statusId, statusInfo } }),
    );
  }

  // ######################################################
  //                        thunks
  // ######################################################
  static getDocumentInfo(payload: Parameters<typeof getDocumentInfo>[0]) {
    // @ts-ignore
    return store.dispatch(getDocumentInfo(payload));
  }

  static saveImage(params: Parameters<typeof saveImage>[0]) {
    return store.dispatch(saveImage(params));
  }

  static listcitations(documentId: string, citations: any) {
    return store.dispatch(citationsListed({ document: documentId, citations: citations }));
  }

  static loadTasks(order: any[], tasks: any) {
    return store.dispatch(
      loadTasks({
        order,
        tasks,
      }),
    );
  }

  static citationsLoaded(payload: Parameters<typeof citationsLoaded>[0]) {
    store.dispatch(citationsLoaded(payload));
  }

  static loadDeletedTasks(order: any[], tasks: any) {
    return store.dispatch(
      loadDeletedTasks({
        deleted: order,
        tasks,
      }),
    );
  }

  static updateTask(task: any) {
    return store.dispatch(updateTask(task));
  }

  static loadListStyles(styles: any) {
    store.dispatch(loadListStyles(styles));
  }

  static setTaskOverlayData(data: any) {
    store.dispatch(setTaskOverlayData(data));
  }

  static updateTemplate(documentId: string, templateId: string) {
    store.dispatch(updateData({ objectId: documentId, data: { template: templateId } }));
  }

  static getCitationsObjects() {
    const document = getDocumentObject(store.getState());
    if (document) {
      return document.citations || [];
    }
    return [];
  }

  static cleanPermissionsState() {
    return store.dispatch(cleanPermissionsState());
  }

  static openDeleteCaptionConfirmationModal() {
    const data = {
      modal: 'ConfirmationModal' as Modals,
      data: {
        isOpen: true,
        title: 'DELETE_CAPTION',
        message: 'DELETING_THIS_CAPTION_MAY_CAUSE_ISSUES_CONFIRM',
        confirmButtonTextId: 'DELETE_CAPTION',
        confirmButtonType: 'danger',
        cancelButtonShow: true,
        cancelButtonTextId: 'global.cancel',
        headerType: 'error',
        actionCode: 'confirmDeleteCaption',
      },
    };
    store.dispatch(openAndUpdateModal(data));
  }

  static setSpellcheckCurrLang() {
    store.dispatch(toggleLanguageTrigger());
  }

  static setFindAndReplaceSelectedText(selectedText: FindAndReplaceSliceState['selectedText']) {
    return store.dispatch(setSelectedText({ selectedText }));
  }
  static setFindAndReplaceCurrentResult(payload: Parameters<typeof setCurrentResult>[0]) {
    return store.dispatch(setCurrentResult(payload));
  }
  static setReferenceStyleId(payload: Parameters<typeof setReferenceStyleId>[0]) {
    store.dispatch(setReferenceStyleId(payload));
  }

  static referenceStylesLoaded(payload: Parameters<typeof referenceStylesLoaded>[0]) {
    return store.dispatch(referenceStylesLoaded(payload));
  }

  static setSelectedCitation(payload: Parameters<typeof setSelectedCitation>[0]) {
    store.dispatch(setSelectedCitation(payload));
  }

  static loadedPermissions(payload: Parameters<typeof loadedPermissions>[0]) {
    store.dispatch(loadedPermissions(payload));
  }

  static loadNodesForApproval(payload: Parameters<typeof loadNodesForApproval>[0]) {
    store.dispatch(loadNodesForApproval(payload));
  }

  static loadLanguages(payload: Parameters<typeof loadLanguageData>[0]) {
    return store.dispatch(loadLanguageData(payload));
  }

  static zoomIn() {
    return store.dispatch(updateZoomValue(store.getState().editor.status.zoom * 100 + 10));
  }

  static zoomOut() {
    return store.dispatch(updateZoomValue(store.getState().editor.status.zoom * 100 - 10));
  }

  static getCompletedActions() {
    return store.getState().onboarding.actionsCompleted;
  }

  static getOnboardingState() {
    return store.getState().onboarding;
  }

  static isEnvision() {
    return selectIsIEnvision(store.getState());
  }

  static completeAction(payload: Parameters<typeof completeAction>[0]) {
    return store.dispatch(completeAction(payload));
  }

  static setPulseData(payload: Parameters<typeof setPulseData>[0]) {
    return store.dispatch(setPulseData(payload));
  }

  static setWordsQuantity(payload: Parameters<typeof setWordsQuantity>[0]) {
    return store.dispatch(setWordsQuantity(payload));
  }

  static setCanUndo(payload: Parameters<typeof setCanUndo>[0]) {
    return store.dispatch(setCanUndo(payload));
  }

  static setCanRedo(payload: Parameters<typeof setCanRedo>[0]) {
    return store.dispatch(setCanRedo(payload));
  }

  static setBlockWindow(payload: Parameters<typeof setBlockWindow>[0]) {
    return store.dispatch(setBlockWindow(payload));
  }
}
