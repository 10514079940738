import { BaseOperation } from '../BaseOperation';
import { ParseFigureForParagaphOperation } from './ParseFigureForParagaphOperation';
import { NodeUtils } from 'Editor/services/DataManager/models';

export class UpdateImagePropertiesOperation extends BaseOperation<Editor.Data.Node.Model> {
  private offsets: Editor.Common.Rect;
  private imageData: Editor.Data.Node.ImageData;
  private properties: Editor.Styles.ImageProperties;
  protected path: Editor.Selection.Path;

  constructor(
    baseModel: Editor.Data.Node.Model,
    path: Editor.Selection.Path,
    offsets: Editor.Common.Rect,
    imageData: Editor.Data.Node.ImageData,
    properties: Editor.Styles.ImageProperties,
  ) {
    super(baseModel);
    this.offsets = offsets;
    this.imageData = imageData;
    this.properties = properties;
    this.path = path;

    this.build();
  }

  protected build(): Editor.Edition.IOperationBuilder {
    if (!this.model || !this.path || !this.imageData) {
      return this;
    }

    // TODO:
    // check edtion with inline images
    // check clipboard

    // check if level0 is a Figure and change to paragraph
    if (NodeUtils.isFigureData(this.model.get())) {
      const parseFigureForParagaphOperation = new ParseFigureForParagaphOperation(this.model);
      this.ops.push(...parseFigureForParagaphOperation.getOps());
    }

    if (NodeUtils.isImageData(this.imageData)) {
      switch (this.properties.imageWrap?.value) {
        case 'inline':
          this.updateImageWrapInlineOperation();
          break;
        case 'behindText':
        case 'inFrontText':
          this.updateImageWrapAbsoluteOperation();
          break;
        case 'left':
        case 'right':
          this.updateImageWrapTextOperation();
          break;
        case 'topAndBottom':
          this.updateImageWrapTopBottomOperation();
          break;
      }
    }

    return this;
  }

  private updateImageWrapInlineOperation() {
    if (this.imageData && this.path) {
      if (this.imageData.properties?.f !== undefined) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.f, null, [
          ...this.path,
          'properties',
          'f',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      if (this.imageData.properties?.bd !== undefined) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.bd, null, [
          ...this.path,
          'properties',
          'bd',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      if (this.imageData.properties?.lc !== undefined) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.lc, null, [
          ...this.path,
          'properties',
          'lc',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      if (this.imageData.properties?.l !== undefined) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.l, null, [
          ...this.path,
          'properties',
          'l',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      if (this.imageData.properties?.ao !== undefined) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.ao, null, [
          ...this.path,
          'properties',
          'ao',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      if (this.imageData.properties?.ax !== undefined) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.ax, null, [
          ...this.path,
          'properties',
          'ax',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      if (this.imageData.properties?.ay !== undefined) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.ay, null, [
          ...this.path,
          'properties',
          'ay',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      if (this.imageData.properties?.ox !== undefined) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.ox, null, [
          ...this.path,
          'properties',
          'ox',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      if (this.imageData.properties?.oy !== undefined) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.oy, null, [
          ...this.path,
          'properties',
          'oy',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      if (this.imageData.properties?.rfx !== undefined) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.rfx, null, [
          ...this.path,
          'properties',
          'rfx',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      if (this.imageData.properties?.rfy !== undefined) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.rfy, null, [
          ...this.path,
          'properties',
          'rfy',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      if (this.imageData.properties?.wr !== undefined) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.wr, null, [
          ...this.path,
          'properties',
          'wr',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }
    }
  }

  private updateImageWrapAbsoluteOperation() {
    const margins = this.properties.margins;
    const offsets = this.offsets;
    const imageWrap = this.properties?.imageWrap?.value;

    if (this.imageData && this.path) {
      if (this.imageData.properties?.f !== true) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.f, true, [
          ...this.path,
          'properties',
          'f',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      let behindDoc;
      if (imageWrap === 'behindText' && this.imageData.properties?.bd !== true) {
        behindDoc = true;
      } else if (imageWrap === 'inFrontText' && this.imageData.properties?.bd !== false) {
        behindDoc = false;
      }
      if (behindDoc != null) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.bd, behindDoc, [
          ...this.path,
          'properties',
          'bd',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      if (this.imageData.properties?.lc !== true) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.lc, true, [
          ...this.path,
          'properties',
          'lc',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      if (this.imageData.properties?.l !== false) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.l, false, [
          ...this.path,
          'properties',
          'l',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      if (this.imageData.properties?.ao !== true) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.ao, true, [
          ...this.path,
          'properties',
          'ao',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      if (this.imageData.properties?.ax !== undefined) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.ax, null, [
          ...this.path,
          'properties',
          'ax',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      if (this.imageData.properties?.ay !== undefined) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.ay, null, [
          ...this.path,
          'properties',
          'ay',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      if (offsets.left != null) {
        let op = this.getObjectOperationforPathValue(
          this.imageData.properties?.ox,
          EditorDOMUtils.convertUnitTo(offsets.left, 'px', 'pt', 3),
          [...this.path, 'properties', 'ox'],
        );
        if (op) {
          this.ops.push(op);
        }
      }

      if (offsets.top != null) {
        let op = this.getObjectOperationforPathValue(
          this.imageData.properties?.oy,
          EditorDOMUtils.convertUnitTo(offsets.top, 'px', 'pt', 3),
          [...this.path, 'properties', 'oy'],
        );
        if (op) {
          this.ops.push(op);
        }
      }

      if (this.imageData.properties?.rfx !== 'c') {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.rfx, 'c', [
          ...this.path,
          'properties',
          'rfx',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      if (this.imageData.properties?.rfy !== 'p') {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.rfy, 'p', [
          ...this.path,
          'properties',
          'rfy',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      let wrapping: Editor.Data.Node.ImageProperties['wr'] = {
        t: 'n',
        td: margins?.top?.value || this.imageData.properties?.wr?.td || 0,
        bd: margins?.bottom?.value || this.imageData.properties?.wr?.bd || 0,
        ld: margins?.left?.value || this.imageData.properties?.wr?.ld || 0,
        rd: margins?.right?.value || this.imageData.properties?.wr?.rd || 0,
      };

      let op = this.getObjectOperationforPathValue(this.imageData.properties?.wr, wrapping, [
        ...this.path,
        'properties',
        'wr',
      ]);
      if (op) {
        this.ops.push(op);
      }
    }
  }

  private updateImageWrapTextOperation() {
    const ops: Realtime.Core.RealtimeOp[] = [];
    const margins = this.properties.margins;
    const offsets = this.offsets;
    const imageWrap = this.properties?.imageWrap?.value;

    if (this.imageData && this.path) {
      if (this.imageData.properties?.f !== true) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.f, true, [
          ...this.path,
          'properties',
          'f',
        ]);
        if (op) {
          ops.push(op);
        }
      }

      if (this.imageData.properties?.bd !== false) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.bd, false, [
          ...this.path,
          'properties',
          'bd',
        ]);
        if (op) {
          ops.push(op);
        }
      }

      if (this.imageData.properties?.lc !== true) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.lc, true, [
          ...this.path,
          'properties',
          'lc',
        ]);
        if (op) {
          ops.push(op);
        }
      }

      if (this.imageData.properties?.l !== false) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.l, false, [
          ...this.path,
          'properties',
          'l',
        ]);
        if (op) {
          ops.push(op);
        }
      }

      if (this.imageData.properties?.ao !== true) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.ao, true, [
          ...this.path,
          'properties',
          'ao',
        ]);
        if (op) {
          ops.push(op);
        }
      }

      let align;
      if (imageWrap === 'left') {
        align = 'l';
      } else if (imageWrap === 'right') {
        align = 'r';
      }

      if (align !== undefined) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.ax, align, [
          ...this.path,
          'properties',
          'ax',
        ]);
        if (op) {
          ops.push(op);
        }
      }

      if (this.imageData.properties?.ay !== undefined) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.ay, null, [
          ...this.path,
          'properties',
          'ay',
        ]);
        if (op) {
          ops.push(op);
        }
      }

      if (this.imageData.properties?.ox !== undefined) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.ox, null, [
          ...this.path,
          'properties',
          'ox',
        ]);
        if (op) {
          ops.push(op);
        }
      }

      if (this.imageData.properties?.oy !== 0) {
        let op = this.getObjectOperationforPathValue(
          this.imageData.properties?.oy,
          EditorDOMUtils.convertUnitTo(offsets.top || 0, 'px', 'pt', 3),
          [...this.path, 'properties', 'oy'],
        );
        if (op) {
          ops.push(op);
        }
      }

      if (this.imageData.properties?.rfx !== 'c') {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.rfx, 'c', [
          ...this.path,
          'properties',
          'rfx',
        ]);
        if (op) {
          ops.push(op);
        }
      }

      if (this.imageData.properties?.rfy !== 'p') {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.rfy, 'p', [
          ...this.path,
          'properties',
          'rfy',
        ]);
        if (op) {
          ops.push(op);
        }
      }

      let wrapping: Editor.Data.Node.ImageProperties['wr'] = {
        t: 'ti',
        td: this.imageData.properties?.wr?.td || 0,
        bd: this.imageData.properties?.wr?.bd || 0,
        ld: this.imageData.properties?.wr?.ld || 9.07,
        rd: this.imageData.properties?.wr?.rd || 9.07,
        wt: 'bs',
        wp: {
          lt: [
            { x: 0, y: 1.655 },
            { x: 1.678, y: 1.655 },
            { x: 1.678, y: 0 },
            { x: 0, y: 0 },
          ],
          s: { x: 0, y: 0 },
        },
      };

      if (margins?.top?.value != null) {
        wrapping.td = margins.top.value;
      }

      if (margins?.bottom?.value != null) {
        wrapping.bd = margins.bottom.value;
      }

      if (margins?.left?.value != null) {
        wrapping.ld = margins.left.value;
      }

      if (margins?.right?.value != null) {
        wrapping.rd = margins.right.value;
      }

      let op = this.getObjectOperationforPathValue(this.imageData.properties?.wr, wrapping, [
        ...this.path,
        'properties',
        'wr',
      ]);
      if (op) {
        ops.push(op);
      }
    }

    return ops;
  }

  private updateImageWrapTopBottomOperation() {
    const margins = this.properties.margins;
    const offsets = this.offsets;

    if (this.imageData && this.path) {
      if (this.imageData.properties?.f !== true) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.f, true, [
          ...this.path,
          'properties',
          'f',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      if (this.imageData.properties?.bd !== false) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.bd, false, [
          ...this.path,
          'properties',
          'bd',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      if (this.imageData.properties?.lc !== true) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.lc, true, [
          ...this.path,
          'properties',
          'lc',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      if (this.imageData.properties?.l !== false) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.l, false, [
          ...this.path,
          'properties',
          'l',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      if (this.imageData.properties?.ao !== true) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.ao, true, [
          ...this.path,
          'properties',
          'ao',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      if (this.imageData.properties?.ax !== 'c') {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.ax, 'c', [
          ...this.path,
          'properties',
          'ax',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      if (this.imageData.properties?.ay !== undefined) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.ay, null, [
          ...this.path,
          'properties',
          'ay',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      if (this.imageData.properties?.ox) {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.ox, null, [
          ...this.path,
          'properties',
          'ox',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      if (offsets.top) {
        let op = this.getObjectOperationforPathValue(
          this.imageData.properties?.oy,
          EditorDOMUtils.convertUnitTo(offsets.top, 'px', 'pt', 3),
          [...this.path, 'properties', 'oy'],
        );
        if (op) {
          this.ops.push(op);
        }
      }

      if (this.imageData.properties?.rfx !== 'c') {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.rfx, 'c', [
          ...this.path,
          'properties',
          'rfx',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      if (this.imageData.properties?.rfy !== 'p') {
        let op = this.getObjectOperationforPathValue(this.imageData.properties?.rfy, 'p', [
          ...this.path,
          'properties',
          'rfy',
        ]);
        if (op) {
          this.ops.push(op);
        }
      }

      let wrapping: Editor.Data.Node.ImageProperties['wr'] = {
        t: 'tb',
        td: margins?.top?.value || this.imageData.properties?.wr?.td || 0,
        bd: margins?.bottom?.value || this.imageData.properties?.wr?.bd || 0,
        ld: margins?.left?.value || this.imageData.properties?.wr?.ld || 0,
        rd: margins?.right?.value || this.imageData.properties?.wr?.rd || 0,
        wt: 'bs',
      };

      let op = this.getObjectOperationforPathValue(this.imageData.properties?.wr, wrapping, [
        ...this.path,
        'properties',
        'wr',
      ]);
      if (op) {
        this.ops.push(op);
      }
    }
  }
}
