import { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { InteractionController } from '_common/components';
import {
  LikeToggle,
  Options,
  Priority,
  ReplyList,
  ReplyToggle,
  UserPresentation,
  ViewRichTextEditor,
  ResolveButton,
} from '_common/suite/components/Card';
import { Card } from '_common/suite/components';
import PageLayoutTooltip from '../PageLayoutTooltip/PageLayoutTooltip';

import { useDispatch, useSelector } from '_common/hooks';
import { LikeToggleProps } from '_common/suite/components/Card/LikeToggle/LikeToggle';
import EditorManager from 'Editor/services/EditorManager';
import { notify } from '_common/components/ToastSystem';
import { selectCollaborators } from 'App/redux/appSlice';
import { containsMention, stringToRichText } from 'utils';
import { completeAction, setPulseData } from 'App/redux/onboardingSlice';
import { getDocumentObject, selectIsPageLayout } from 'Editor/redux/EditorStatusSlice';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import styles from './CommentCard.module.scss';
import { useVirtualizedList } from '_common/suite/components/Card/VirtualizedList/VirtualizedListContext';

type ViewCommentCarProps = {
  user: UserId;
  id?: string;
  comment: Editor.Comment;
  mainComment?: MyAny;
  sidebar?: boolean;
  subcomment?: boolean;
  isTask?: boolean;
  selected?: boolean;
  isReadOnlyMode?: boolean;
  setEditMode: (isEdit: boolean) => void;
  testId: string;
};

const ViewCommentCard = ({
  id,
  comment,
  testId,
  user,
  isReadOnlyMode,
  selected,
  sidebar,
  setEditMode,
}: ViewCommentCarProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { openReplies, setReplyState, virtualized } = useVirtualizedList();

  const isPageLayout = useSelector(selectIsPageLayout);

  //#region Permissions
  const document = useSelector(getDocumentObject);

  const isAuthor = (comment: Editor.Comment) => comment.author === user;
  const isOwner =
    document.user_permissions.includes('admin') || document.user_permissions.includes('owner');
  // Change a comment's priority:  Owner, Comment Creator or Document Owner permission
  const canChangePriority = (comment: Editor.Comment) => isOwner || isAuthor(comment);
  // Edit a comment: Comment Creator
  const canEdit = (comment: Editor.Comment) => isAuthor(comment) && !isReadOnlyMode;
  const canComment = document.user_permissions.includes('comment');
  // Delete a comment: Comment Creator
  const canDelete = (comment: Editor.Comment) =>
    (isOwner || (isAuthor(comment) && canComment)) && !isReadOnlyMode;
  const canResolve = (comment: Editor.Comment) => (isAuthor(comment) || isOwner) && !isReadOnlyMode;
  //#endregion

  const [localShowReplies, setLocalShowReplies] = useState(false);
  const showReplies = useMemo(
    () => (virtualized ? !!openReplies[comment.id] : localShowReplies),
    [virtualized, openReplies, comment, localShowReplies],
  );

  //#region Onboarding
  const actionsCompleted = useSelector((state) => state.onboarding.actionsCompleted);
  const pulseData = useSelector((state) => state.onboarding.pulseData);

  const replyToggleRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!pulseData.commentId && comment?.id) {
      dispatch(setPulseData({ commentId: comment?.id }));
    }
  }, [pulseData]);

  useEffect(() => {
    if (replyToggleRef?.current) {
      if (!showReplies && !actionsCompleted.editor_comments_mentionInCommentReply) {
        dispatch(
          setPulseData({
            annotationCardReplyRect: {
              top: replyToggleRef.current.offsetTop,
              left: replyToggleRef.current.offsetLeft,
              height: replyToggleRef.current.offsetHeight,
              width: replyToggleRef.current.offsetWidth,
            },
          }),
        );
      } else {
        dispatch(
          setPulseData({
            annotationCardReplyRect: undefined,
          }),
        );
      }
    }

    return () => {
      dispatch(
        setPulseData({
          annotationCardReplyRect: undefined,
        }),
      );
    };
  }, [showReplies]);

  //#endregion

  const collaborators = useSelector((state) =>
    selectCollaborators(state, state.editor.status.documentId),
  );

  const priority = useMemo(() => {
    switch (comment.priority) {
      case 'HIGH': {
        return 'High';
      }
      case 'MEDIUM': {
        return 'Medium';
      }
      case 'LOW': {
        return 'Low';
      }
    }
  }, [comment]);

  const hasNotImportedContent = useMemo(() => {
    return comment && comment.content && comment.content.includes('( ! )');
  }, [comment]);

  const handleSelectComment = () => {
    if (!selected && comment.status === 'OPEN') {
      EditorManager.getInstance().focusComment(comment.id);
    }
  };

  //#region Card methods
  const parseReply = (originalReply: Editor.Comment): Card.Reply => {
    const reply: Card.Reply = {
      id: originalReply.id,
      authorId: originalReply.author,
      //@ts-expect-error creationDate type should be string
      creationDate: originalReply.time,
      votes: originalReply.votes,
      content: { dir: 'ltr', content: stringToRichText(originalReply.content) },
      title: originalReply.user,
      application: originalReply.application,
    };

    return reply;
  };

  const parsedReplies = useMemo(
    () => comment.comments.map((comment) => parseReply(comment)),
    [comment],
  );

  const handleToggleReplies = () => {
    if (virtualized) {
      setReplyState(comment.id, showReplies ? null : 'open');
    } else {
      setLocalShowReplies(!showReplies);
    }
  };

  const handleEditComment = () => {
    setEditMode(true);
  };

  const handleChangePriority = (priority: Card.Priority) => {
    if (comment.status === 'OPEN') {
      let newPriotiy: typeof comment.priority = 'MEDIUM';

      switch (priority) {
        case 'High': {
          newPriotiy = 'HIGH';
          break;
        }
        case 'Medium': {
          newPriotiy = 'MEDIUM';
          break;
        }
        case 'Low': {
          newPriotiy = 'LOW';
          break;
        }
        default: {
          break;
        }
      }

      if (comment.priority !== newPriotiy) {
        EditorManager.getInstance().changeCommentPriority(comment.id, newPriotiy);
      }
    }
  };

  const handleDeleteComment = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ConfirmationModal',
        data: {
          title: 'DELETE_COMMENT',
          message: 'DELETING_THIS_COMMENT_WILL_PERMANENTLY_REMOVE_IT_CONFIRM',
          cancelButtonTextId: 'global.cancel',
          confirmButtonType: 'danger',
          confirmButtonTextId: 'global.delete',
          headerType: 'error',
          actionCode: 'deleteComment',
          actionValue: {
            commentId: comment.id,
          },
          cancelButtonShow: true,
        },
      }),
    );
  };

  const handleVoteComment: LikeToggleProps['onVote'] = ({ replyId, currentUserLiked }) => {
    if (replyId) {
      EditorManager.getInstance().voteReply(comment.id, replyId, currentUserLiked ? false : true);
    } else {
      EditorManager.getInstance().voteComment(comment.id, currentUserLiked ? false : true);
    }
  };

  const handleResolveComment = () => {
    EditorManager.getInstance()
      .resolveComment(comment.id)
      .then(() => {
        notify({
          type: 'success',
          title: 'notifications.commentResolve.messageSuccess',
          message: 'THE_COMMENT_WAS_SUCCESSFULLY_RESOLVED',
        });
      })
      .catch((error) => {
        notify({
          type: 'error',
          title: 'global.comment',
          message: 'notifications.commentResolve.messageError',
        });
        throw error;
      });
  };

  const handleCreateReply = (reply: string) => {
    EditorManager.getInstance().replyComment(comment.id, stringToRichText(reply));

    if (containsMention(stringToRichText(reply))) {
      dispatch(completeAction('editor_comments_mentionInCommentReply'));
    }
  };
  const handleDeleteReply = (replyId: string) => {
    EditorManager.getInstance().deleteReply(comment.id, replyId);
  };
  const handleEditReply = ({ replyId, newContent }: { replyId: string; newContent: string }) => {
    EditorManager.getInstance().editReply(comment.id, replyId, stringToRichText(newContent));
  };
  const canEditReply = (replyId: string) => {
    const reply = comment.comments.find((reply) => reply.id === replyId);
    if (reply) {
      return canEdit(reply);
    }
    return false;
  };
  const canDeleteReply = (replyId: string) => {
    const reply = comment.comments.find((reply) => reply.id === replyId);
    if (reply) {
      return canDelete(reply);
    }

    return false;
  };
  const canVoteReply = () => {
    return !isReadOnlyMode;
  };
  //#endregion

  return (
    <div style={{ position: 'relative' }}>
      <InteractionController environment="editor">
        <Card
          id={id ?? `Comment#${comment.id}`}
          selected={selected}
          sidebar={sidebar}
          onClick={handleSelectComment}
          testId={testId}
          width={sidebar ? '100%' : '43rem'}
        >
          {/* Card content
          {/[0-9]+$/.test(comment.id) ? <div style={{ height: '200px' }}>Even</div> : null} */}
          <Card.Header>
            <Card.Header.Left>
              <UserPresentation
                userId={comment.author}
                fixedName={!comment.author ? comment.user : undefined}
                creationDate={comment.time}
              />
            </Card.Header.Left>
            <Card.Header.Right>
              <PageLayoutTooltip
                type="comment"
                content={intl.formatMessage({
                  id:
                    isReadOnlyMode || !canChangePriority(comment)
                      ? 'CHANGE_PRIORITY_CARD'
                      : 'global.priority',
                })}
                testId={`comment-card-${comment.id}-priority-tooltip`}
              >
                <Priority
                  priority={priority}
                  onChange={handleChangePriority}
                  testId={testId}
                  disabled={isReadOnlyMode || !canChangePriority(comment)}
                />
              </PageLayoutTooltip>
              <PageLayoutTooltip
                type="comment"
                content={intl.formatMessage({ id: 'global.moreActions' })}
                testId={`comment-card-${comment.id}-more-options-tooltip`}
              >
                <Options
                  editOption={{
                    onClick: handleEditComment,
                    disabled: !canEdit(comment),
                    tooltip: {
                      content: comment.application
                        ? intl.formatMessage({ id: 'CANNOT_EDIT_COMMENT_IMPORTED_FROM_WORD' })
                        : intl.formatMessage({ id: 'CANNOT_EDIT_COMMENT_CREATED_BY_OTHERS' }),
                      placement: 'bottom',
                      disabled: canEdit(comment),
                      testId: `comment-card-${comment.id}-more-options-edit-dropdown-item-tooltip`,
                    },
                  }}
                  deleteOption={{
                    onClick: handleDeleteComment,
                    disabled: !canDelete(comment),
                    tooltip: {
                      content: comment.application
                        ? intl.formatMessage({ id: 'CANNOT_DELETE_COMMENT_IMPORTED_FROM_WORD' })
                        : intl.formatMessage({ id: 'CANNOT_DELETE_COMMENT_CREATED_BY_OTHERS' }),
                      placement: 'bottom',
                      disabled: canDelete(comment),
                      testId: `comment-card-${comment.id}-more-options-delete-dropdown-item-tooltip`,
                    },
                  }}
                  disabled={isReadOnlyMode}
                  testId={testId}
                />
              </PageLayoutTooltip>
            </Card.Header.Right>
          </Card.Header>
          <Card.Body>
            {comment.application === 'docx' && (
              <div className={styles.imported}>
                <FormattedMessage id="IMPORTED_FROM_WORD_DOCUMENT" />
              </div>
            )}
            <ViewRichTextEditor
              initialValue={comment.content}
              dependencies={[comment.content]}
              testId={`comment-card-${comment.id}-rich-text-editor`}
            />

            {hasNotImportedContent && (
              <div className={styles.notImportedContent}>
                <FormattedMessage id="CONTENT_NOT_IMPORTED" />
              </div>
            )}
          </Card.Body>
          <Card.Footer>
            <Card.Footer.Left>
              <PageLayoutTooltip type="comment" testId={`comment-card-${comment.id}-like-tooltip`}>
                <LikeToggle
                  votes={comment.votes ?? []}
                  target="comment"
                  onVote={handleVoteComment}
                  disabled={isReadOnlyMode}
                  testId={testId}
                />
              </PageLayoutTooltip>
              <PageLayoutTooltip
                type="comment"
                disabled={comment?.comments?.length > 0 || undefined}
                testId={`comment-card-${comment.id}-reply-tooltip`}
              >
                <InteractionController
                  environment="editor"
                  rules={[
                    {
                      interaction: 'editor_sidepanel_mentionComment',
                      actions: ['editor_comments_openReplies'],
                    },
                  ]}
                >
                  <ReplyToggle
                    repliesCount={comment.comments?.length ?? 0}
                    isToggled={showReplies}
                    onToggleReplies={handleToggleReplies}
                    canComment={!isReadOnlyMode}
                    //Used for OnboardingEditor/Standalone
                    id="comment-card-reply-toggle"
                    ref={replyToggleRef}
                    testId={testId}
                  />
                </InteractionController>
              </PageLayoutTooltip>
            </Card.Footer.Left>
            <Card.Footer.Right>
              <PageLayoutTooltip
                type="comment"
                testId={`comment-card-${comment.id}-resolve-tooltip`}
              >
                <ResolveButton
                  onClick={handleResolveComment}
                  disabled={!canResolve(comment)}
                  testId={testId}
                />
              </PageLayoutTooltip>
            </Card.Footer.Right>
          </Card.Footer>
          {showReplies && (
            <ReplyList
              replies={parsedReplies ?? []}
              commentId={comment.id}
              collaborators={collaborators}
              canComment={!isReadOnlyMode}
              optionsToggleTooltip={{
                content: intl.formatMessage({
                  id: 'CANNOT_MAKE_CHANGES_TO_COMMENTS_IN_PAGE_LAYOUT',
                }),
                disabled: !isPageLayout,
                testId: `comment-card-${comment.id}-reply-options-tooltip`,
              }}
              likeToggleTooltip={{
                content: intl.formatMessage({
                  id: 'CANNOT_MAKE_CHANGES_TO_COMMENTS_IN_PAGE_LAYOUT',
                }),
                disabled: !isPageLayout,
                testId: `task-card-${comment.id}-reply-like-tooltip`,
              }}
              createReply={handleCreateReply}
              editReply={handleEditReply}
              deleteReply={handleDeleteReply}
              voteReply={handleVoteComment}
              canEditReply={canEditReply}
              canDeleteReply={canDeleteReply}
              canVoteReply={canVoteReply}
              newReplyInteraction={{
                environment: 'editor',
                rules: [
                  {
                    interaction: 'editor_sidepanel_mentionComment',
                    actions: ['editor_comments_mentionInCommentReply'],
                  },
                ],
                style: { flexDirection: 'column' },
              }}
              testId={`${testId}-replyList`}
            />
          )}
        </Card>
      </InteractionController>
    </div>
  );
};

export default ViewCommentCard;
