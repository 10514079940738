import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { EmptyCardListState } from 'dodoc-design-system';

import { usePublicProfiles, useSelector } from '_common/hooks';
import usePDFData from 'PDF/hooks/usePDFData';
import { useGetCurrentUserQuery } from '_common/services/api/authority';

import {
  selectPDFAnnotations,
  selectFilteredPDFAnnotations,
  selectAnnotationsAuthors,
  selectAllPDFAnnotations,
} from 'PDF/redux/PDFAnnotationsSlice';
import { annotationBelongsToGroupType } from 'PDF/redux/PDFAnnotationsSlice';
import { selectHasFilters } from '_common/components/Filters/FilterSlice';

import { EmptyFilteredState, FilterDisplay } from '_common/components';
import { CommentFilters } from '_common/suite/components';
import { VirtualizedList } from '_common/suite/components/Card';
import { VirtualizedListProps } from '_common/suite/components/Card/VirtualizedList/VirtualizedList';

import Header from '../Header/Header';
import Search from './Search/Search';
import AnnotationCard from './AnnotationCard/AnnotationCard';

import panelStyles from '../RightSidePanel.module.scss';
import styles from './AnnotationsPanel.module.scss';

const Annotations = () => {
  const object = usePDFData();
  const annotationList = useSelector(selectPDFAnnotations);
  const allAnnotationList = useSelector(selectAllPDFAnnotations);
  const annotationsFilteredList = useSelector(selectFilteredPDFAnnotations);
  const selectedAnnotation = useSelector((state) => state.pdf.annotations.selected);
  const searchBy = useSelector((state) => state.pdf.annotations.searchBy);
  const hasActiveFilters = useSelector((state) =>
    selectHasFilters(state, state.filters.reviewerCommentPanel),
  );
  const { data: userProfile } = useGetCurrentUserQuery();
  const users = useSelector(selectAnnotationsAuthors);
  const { profiles } = usePublicProfiles(users.map((user) => user.id));

  const selectedAnnotationIndex = useMemo(
    () =>
      selectedAnnotation
        ? annotationsFilteredList.list.findIndex((itId) => itId === selectedAnnotation)
        : -1,
    [selectedAnnotation],
  );

  const userOptions = useMemo(() => {
    const options: { label: string; id: string; email?: string; value: string }[] = [];

    users.forEach((user) => {
      const option: { label: string; id: string; email?: string; value: string } = {
        label: '',
        id: '',
        value: '',
      };
      if (user.imported) {
        option.label = user.id;
        option.id = 'IMPORTED_USER';
      } else {
        option.label = profiles[user.id]?.name;
        option.email = profiles[user.id]?.email;
        option.id = user.id;
      }
      option.value = user.id;
      options.push(option);
    });

    return options;
  }, [users, profiles]);

  if (!object || !userProfile) {
    return null;
  }

  const renderEmptyState = () => {
    if (searchBy) {
      return (
        <div className={styles.emptyStateSearchBy} data-testid="sidebar-comments-empty-search">
          <FormattedMessage id="NO_MATCHES_FOUND" />
        </div>
      );
    }

    return <EmptyFilteredState identity="reviewerCommentPanel" size="medium" />;
  };

  const itemRenderer: VirtualizedListProps['itemRenderer'] = ({ index }) => {
    const annotationId = annotationsFilteredList.list[index];

    return (
      <AnnotationCard
        annotation={annotationsFilteredList.annotations[annotationId]}
        sidebar
        testId={`sidebar-comment-${annotationId}`}
      />
    );
  };

  return (
    <>
      <Header>
        <FormattedMessage id="COMMENTS" />
        <CommentFilters
          categories={{
            commentStatus: {
              options: [
                {
                  value: 'Completed',
                  label: 'editor.sidebar.review.filter.status.resolved',
                  labelNumber: allAnnotationList.filter(
                    (annotation) => annotation.state === 'Completed',
                  ).length,
                },
                {
                  value: 'Cancelled',
                  label: 'editor.sidebar.review.filter.status.deleted',
                  labelNumber: allAnnotationList.filter(
                    (annotation) => annotation.state === 'Cancelled',
                  ).length,
                },
              ],
            },
            commentType: {
              options: [
                {
                  value: 'comment',
                  label: 'COMMENTS',
                  labelNumber: allAnnotationList.filter((annotation) =>
                    annotationBelongsToGroupType('comment', annotation),
                  ).length,
                },
                {
                  value: 'freehand',
                  label: 'FREEHAND',
                  labelNumber: allAnnotationList.filter((annotation) =>
                    annotationBelongsToGroupType('freehand', annotation),
                  ).length,
                },
                {
                  value: 'highlights_notes',
                  label: 'HIGHLIGHTS_AND_NOTES',
                  labelNumber: allAnnotationList.filter((annotation) =>
                    annotationBelongsToGroupType('highlights_notes', annotation),
                  ).length,
                },
                {
                  value: 'shapes',
                  label: 'SHAPES',
                  labelNumber: allAnnotationList.filter((annotation) =>
                    annotationBelongsToGroupType('shapes', annotation),
                  ).length,
                },
                {
                  value: 'textbox',
                  label: 'TEXT_BOX',
                  labelNumber: allAnnotationList.filter((annotation) =>
                    annotationBelongsToGroupType('textbox', annotation),
                  ).length,
                },
              ],
            },
            cardPriority: {
              options: [
                { value: 'Low', label: 'editor.sidebar.review.filter.priority.low' },
                { value: 'Medium', label: 'editor.sidebar.review.filter.priority.medium' },
                { value: 'High', label: 'editor.sidebar.review.filter.priority.high' },
              ],
            },
            author: { options: userOptions, settings: { editorAvatar: true } },
            reviewerCreationDate: {
              options: [
                { value: 'last_week', label: 'LAST_WEEK' },
                { value: 'last_month', label: 'LAST_MONTH' },
                { value: '3_month_ago', label: 'NUMBER_MONTH_AGO', labelValue: 3 },
                { value: '6_month_ago', label: 'NUMBER_MONTH_AGO', labelValue: 6 },
                { value: 'specific_date_range', label: 'SPECIFIC_DATE_RANGE' },
              ],
            },
          }}
        />
      </Header>
      <div className={panelStyles.content}>
        {annotationList.length === 0 && !hasActiveFilters ? (
          <div className={styles.emptyState} data-testid="no-comments-yet">
            <EmptyCardListState size="medium" testId="no-comments-empty-card-list-state">
              <FormattedMessage id="NO_COMMENTS_YET" />
            </EmptyCardListState>
          </div>
        ) : (
          <>
            <Search />
            <FilterDisplay
              identity="reviewerCommentPanel"
              direction="column"
              margin="0 0 2rem 1rem"
            />
            <div className={styles.annotationsList} data-testid="sidebar-comments-list">
              {annotationsFilteredList.list.length === 0 ? (
                renderEmptyState()
              ) : (
                <VirtualizedList
                  itemCount={annotationsFilteredList.list.length}
                  selectedIndex={selectedAnnotationIndex}
                  itemMinHeight={138}
                  itemRenderer={itemRenderer}
                />
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Annotations;
