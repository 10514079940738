import {
  Suggestion,
  SuggestionContentData,
  SuggestionData,
  SuggestionDataPriority,
  SuggestionDataType,
} from '../../models';
import BaseController from '../BaseController';
import { SuggestionList } from './SuggestionList';
import { Logger } from '_common/services';
import { IndexerDeltaType } from '../Models/ModelIndexer';

type SuggestionControllerArgType = Pick<Editor.Data.State, 'transport' | 'models' | 'context'>;

export class SuggestionController extends BaseController {
  private suggestionList?: SuggestionList;
  constructor(Data: SuggestionControllerArgType) {
    super(Data);
    this.handleSuggestionListError = this.handleSuggestionListError.bind(this);
    this.handleSuggestionListChanged = this.handleSuggestionListChanged.bind(this);
    this.handleSuggestionChanged = this.handleSuggestionChanged.bind(this);
  }

  get list() {
    return this.suggestionList;
  }

  isSuggestionCreated(suggestionId: string) {
    const model = this.Data.models?.get('SUGGESTION', suggestionId);
    return model && model.loaded === true && model.selectedData() != null;
  }

  getSuggestionById(suggestionId: string) {
    return this.Data.models?.get('SUGGESTION', suggestionId);
  }

  start(documentId: string): void {
    this.suggestionList = this.Data.models?.getIndexer(
      this.Data.models.TYPE_NAME.SUGGESTION,
    ) as SuggestionList;
    this.suggestionList.on('ERROR', this.handleSuggestionListError);
    this.suggestionList?.on('CHANGED_DELTA', this.handleSuggestionListChanged);
    this.suggestionList.start(documentId);
  }

  private async handleSuggestionListChanged(data: IndexerDeltaType<string[]>) {
    const dataObjs = this.suggestionList?.data || {};
    const jobs = [];
    if (data.in.length > 0) {
      for (let index = 0; index < data.in.length; index++) {
        const element = dataObjs[data.in[index]];
        if (!element.loaded) {
          jobs.push(
            element.awaitForEvent('LOADED').then(() => {
              this.handleSuggestionCreated(element.selectedData());
              element.on('LOADED', this.handleSuggestionChanged);
              element.on('UPDATED', this.handleSuggestionChanged);
              return;
            }),
          );
        } else {
          this.handleSuggestionCreated(element.selectedData());
          element.on('LOADED', this.handleSuggestionChanged);
          element.on('UPDATED', this.handleSuggestionChanged);
        }
      }
    }
    await Promise.all(jobs);
    this.loadSuggestionData();
  }

  private loadSuggestionData() {
    const suggestionData: { [index: string]: SuggestionData } = {};
    const suggestionList = this.suggestionList?.list || [];
    const dataObjs = this.suggestionList?.data || {};
    for (let index = 0; index < suggestionList.length; index++) {
      suggestionData[suggestionList[index]] = {
        content: {
          inserted: null,
          deleted: null,
        },
        type: null,
        location: null,
        priority: null,
        status: null,
        comments: [],
        votes: [],
        id: suggestionList[index],
        ...dataObjs[suggestionList[index]].get([]),
      };
    }

    this.Data.events?.emit('SUGGESTION_LOAD', suggestionList, suggestionData);
  }

  private handleSuggestionListError(error: Error) {
    Logger.error(error);
  }

  private handleSuggestionChanged(data: any) {
    this.Data.events?.emit('UPDATE_SUGGESTION', data);
  }

  private handleSuggestionCreated(data: SuggestionData | null) {
    this.Data.events?.emit('CREATE_SUGGESTION', data);
  }

  get userLogged() {
    return this.Data.users?.loggedUserId;
  }

  getSuggestionModelById(suggestionId: string): Suggestion | undefined {
    return this.Data.models?.get('SUGGESTION', suggestionId);
  }

  isUserAuthorOfSuggestion(suggestionId: string) {
    if (this.userLogged) {
      return this.list?.data[suggestionId].isAuthor(this.userLogged);
    }
    return false;
  }

  getTrackedActionLocation(suggestionId: string) {
    return this.suggestionList?.suggestionLocation(suggestionId);
  }

  async addTrackedAction(
    type: SuggestionDataType,
    inserted: SuggestionContentData,
    deleted: SuggestionContentData,
    location: string[],
    id: string,
  ) {
    const action: SuggestionData = {
      id,
      content: {
        deleted,
        inserted,
      },
      type,
      location,
      priority: 'MEDIUM',
      status: 'OPEN',
      author: this.userLogged ?? null,
      comments: [],
      votes: [],
      time: new Date().toISOString(),
    };
    const newSuggestion = this.Data.models?.get('SUGGESTION', id) as Suggestion;
    return newSuggestion.create(action);
  }

  updateSuggestionContent(
    suggestionId: string,
    newData: SuggestionData['content'],
    options?: Realtime.Core.RealtimeSourceOptions,
  ) {
    return this.getSuggestionById(suggestionId)?.updateSuggestionContent(newData, options);
  }

  /**
   * @description votes a suggestion
   * @author Filipe Assunção
   * @param {String} suggestionId the id of the suggestion to vote
   * @param {boolean} vote the vote value
   * @returns {Promise<any>} Promise with the suggestion id or error
   * @memberof suggestionPersistor
   */
  voteTrackedAction(suggestionId: string, vote: boolean) {
    return this.getSuggestionById(suggestionId)?.vote(vote);
  }

  /**
   * @description persists a suggestion reply
   * @author Filipe Assunção
   * @param {String} suggestionId the id of the suggestion to reply
   * @param {String} content the reply content
   * @returns {Promise<any>} Promise with the suggestion id or error
   * @memberof suggestionPersistor
   */
  replyTrackedAction(suggestionId: string, reply: string) {
    if (this.userLogged) {
      return this.getSuggestionById(suggestionId)?.reply(reply, this.userLogged);
    }
    return this.getSuggestionById(suggestionId)?.reply(reply, null);
  }

  /**
   * @description votes a suggestion reply
   * @author Filipe Assunção
   * @param {String} suggestionId the id of the reply's suggestion
   * @param {String} replyId the id of the reply to vote
   * @param {boolean} vote the vote value
   * @returns {Promise<any>} Promise with the suggestion id or error
   */
  voteTrackedActionReply(suggestionId: string, replyId: string, vote: boolean) {
    return this.getSuggestionById(suggestionId)?.voteReply(replyId, vote);
  }

  /**
   * @description edits a suggestion reply
   * @author Filipe Assunção
   * @param {String} suggestionId the id of the reply's suggestion
   * @param {String} replyId the id of the reply to edit
   * @param {String} newContent the reply new content
   * @returns {Promise<any>} Promise with the suggestion id or error
   */
  editTrackedActionReply(suggestionId: string, replyId: string, newContent: string) {
    return this.getSuggestionById(suggestionId)?.editReply(replyId, newContent);
  }

  /**
   * @description deletes a suggestion reply
   * @author Filipe Assunção
   * @param {String} suggestionId the id of the reply's suggestion
   * @param {String} replyId the id of the reply to delete
   * @returns {Promise<any>} Promise with the suggestion id or error
   */
  deleteTrackedActionReply(suggestionId: string, replyId: string) {
    return this.getSuggestionById(suggestionId)?.deleteReply(replyId);
  }

  /**
   * @description edits a suggestion content
   * @author Filipe Assunção
   * @param {String} suggestionId the id of the suggestion to edit
   * @param {SuggestionDataPriority} priority priority of the suggestion
   * @returns {Promise<any>} Promise with the suggestion id or error
   * @memberof suggestionPersistor
   */
  changeTrackedActionPriority(suggestionId: string, priority: SuggestionDataPriority) {
    return this.getSuggestionById(suggestionId)?.changePriority(priority);
  }

  /**
   * @description resolves a suggestion
   * @author Filipe Assunção
   * @param {String} suggestionId the id of the suggestion to resolve
   * @returns {Promise<any>} Promise with the suggestion id or error
   */
  async acceptTrackedAction(suggestionId: string) {
    await this.getSuggestionById(suggestionId)?.accept();
    this.Data.models?.undoManager?.clearUndoRedo();
  }

  /**
   * @description resolves a suggestion
   * @author Filipe Assunção
   * @param {String} suggestionId the id of the suggestion to resolve
   * @returns {Promise<any>} Promise with the suggestion id or error
   */
  async rejectTrackedAction(suggestionId: string) {
    await this.getSuggestionById(suggestionId)?.reject();
    this.Data.models?.undoManager?.clearUndoRedo();
  }

  toggleTracking(value: boolean) {
    return new Promise<void>((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'DOCUMENT:TRACKING',
        {
          value,
        },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  lockTracking(state: boolean, lock: boolean) {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'LOCK:TRACKING:STATE',
        {
          state,
          lock,
        },
        (response) => {
          if (response.success) {
            resolve(response.payload?.document);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  /**
   * @description resolves a suggestion
   * @author Filipe Assunção
   * @param {String} suggestionId the id of the suggestion to resolve
   * @returns {Promise<any>} Promise with the suggestion id or error
   * @memberof suggestionPersistor
   */
  acceptSuggestions(suggestionIds: string[] | string) {
    this.Data.models?.undoManager?.clearUndoRedo();
    return new Promise<void>((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'TRACKED:ACTION:ACCEPT',
        {
          trackedActionId: suggestionIds,
        },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  /**
   * @description resolves a suggestion
   * @author Filipe Assunção
   * @param {String} suggestionId the id of the suggestion to resolve
   * @returns {Promise<any>} Promise with the suggestion id or error
   * @memberof suggestionPersistor
   */
  rejectSuggestions(suggestionIds: string[] | string) {
    this.Data.models?.undoManager?.clearUndoRedo();
    return new Promise<void>((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'TRACKED:ACTION:REJECT',
        {
          trackedActionId: suggestionIds,
        },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  /**
   * @description Accepts all of the current user pending tracked actions
   * @author Filipe Assunção
   * @returns {Promise<any>} Promise with the suggestion id or error
   * @memberof suggestionPersistor
   */
  acceptAllTrackedActions(trackedActionsRefs: string[]) {
    this.Data.models?.undoManager?.clearUndoRedo();
    return new Promise<void>((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'TRACKED:ACTION:ALL:ACCEPT',
        { trackedActionsRefs: trackedActionsRefs },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject();
          }
        },
      );
    });
  }

  /**
   * @description Rejects all of the current user pending tracked actions
   * @author Filipe Assunção
   * @returns {Promise<any>} Promise with the suggestion id or error
   * @memberof suggestionPersistor
   */
  rejectAllTrackedActions(trackedActionsRefs: string[]) {
    this.Data.models?.undoManager?.clearUndoRedo();
    return new Promise<void>((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'TRACKED:ACTION:ALL:REJECT',
        { trackedActionsRefs: trackedActionsRefs },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject();
          }
        },
      );
    });
  }

  stop(): void {}

  destroy(): void {}

  async applyOpsToSuggestion(
    suggestionId: string,
    ops: Realtime.Core.RealtimeOps,
    options?: Realtime.Core.RealtimeSourceOptions,
  ) {
    const model = this.getSuggestionModelById(suggestionId);
    if (model?.loaded) {
      return model.apply(ops, options);
    }
  }

  async revertOpsToSuggestion(
    suggestionId: string,
    ops: Realtime.Core.RealtimeOps,
    options?: Realtime.Core.RealtimeSourceOptions,
  ) {
    const model = this.getSuggestionModelById(suggestionId);
    if (model?.loaded) {
      return model.revert(ops, options);
    }
  }
}
