import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useParams } from 'react-router';
import { useObject } from '_common/hooks';
import { useGetCurrentUserQuery } from '_common/services/api/authority';

/**
 * Assumes that an admin/owner can do everything, the remaining restrictions are:
 * @property canAccess - has access permission to the Presentation
 * @property canAccessVersionHistory - has access permission to the version history feature
 * @property canSaveVersion - is allowed to save a new version
 * @property canRestoreVersion - is allowed to restore a version
 */
const PERMISSIONS = {
  canAccess: false,
  canAccessVersionHistory: false,
  canSaveVersion: false,
  canRestoreVersion: false,
};

const DEFAULT_PERMISSIONS: { [Property in ApiSchemas['UserPermissionsValues']]: boolean } = {
  admin: false,
  owner: false,
  access: false,
  edit: false,
  delete: false,
  approve: false,
  comment: false,
  add_permission: false,
  remove_permission: false,
  import: false,
  export: false,
};

type ISuiteContext = {
  object: doDOC.PDF | doDOC.Document | doDOC.Presentation;
  permissions: typeof PERMISSIONS;
  app: SuiteProviderProps['app'];
  refetchObject: ReturnType<typeof useObject>['refetch'];
};

type SuiteProviderProps = {
  children: ReactNode;
  app: 'document' | 'dopdf' | 'presentation';
};

const SuiteContext = createContext<ISuiteContext | undefined>(undefined);

const SuiteProvider = ({ children, app }: SuiteProviderProps) => {
  const { id } = useParams<{ id: string }>();
  const { data: object, refetch } = useObject({ object_id: id, object_type: app }, { skip: !id });
  const { data: user } = useGetCurrentUserQuery();

  const context = useMemo(() => {
    if (object && user) {
      const refetchObject = () => {
        return refetch();
      };

      const permissions = { ...PERMISSIONS };
      const userPermissions = object.user_permissions.reduce(
        (permissions, permission) => {
          permissions[permission] = true;
          return permissions;
        },
        { ...DEFAULT_PERMISSIONS },
      );

      userPermissions.admin = user.is_admin || user.is_superuser;
      // There is nothing that an admin can do that an owner cannot
      const isBossMan = userPermissions.admin || userPermissions.owner;

      const isApproved = object.status === 'approved';

      //#region General
      permissions.canAccess = isBossMan || userPermissions.access;
      //#endregion

      //#region Version History
      permissions.canAccessVersionHistory = isBossMan;
      permissions.canSaveVersion = !isApproved && isBossMan;
      permissions.canRestoreVersion = !isApproved && isBossMan;
      //#endregion
      return { permissions, object, app, refetchObject };
    }
  }, [object, user, refetch]);

  if (context) {
    return <SuiteContext.Provider value={context}>{children}</SuiteContext.Provider>;
  }
  return null;
};

const useSuite = () => {
  const context = useContext(SuiteContext);
  if (context === undefined) {
    throw new Error('useSuiteObject must be used within a SuiteProvider');
  }
  return context;
};

export const useSuiteApp = () => {
  return useSuite().app;
};

export const useSuiteObject = () => {
  return useSuite().object;
};

export const useSuitePermissions = () => {
  return useSuite().permissions;
};

export const useSuiteUtils = () => {
  const context = useSuite();
  return { refetchObject: context.refetchObject };
};

// export const use;

export default SuiteProvider;
