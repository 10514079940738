import { isEqual, omit, uniq } from 'lodash';
import { ELEMENTS } from 'Editor/services/consts';
import { RealtimeObject } from '_common/services/Realtime';
import { Transport } from '_common/services/Realtime/Transport';
import { NodeKeys } from './Node.types';
import { Path } from 'sharedb';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { NodeUtils } from './NodeUtils';

const EMPTY_REFS = {
  comments: [],
  tracked: [],
  tempComments: [],
  citations: [],
  fnt: [],
  ent: [],
  f: [],
  br: [],
};

export class NodeModel extends RealtimeObject<Editor.Data.Node.Data> {
  lockStatus: any;
  lockFailSafe: any;

  private _navigationData: Editor.Data.Node.NavigationData[] | null = null;

  static identify(data: Editor.Data.Node.Data, parentId: string) {
    const queue = [
      {
        child: data,
        parent_id: parentId,
      },
    ];
    let it;
    while (queue.length) {
      it = queue.shift();
      if (!it) {
        continue;
      }
      if (it?.child.type !== 'text') {
        if (!it?.child?.id) {
          it.child.id = EditorDOMUtils.generateRandomNodeId();
        }
        if (it?.parent_id) {
          it.child.parent_id = it.parent_id;
        }
        if (it?.child.childNodes?.length) {
          for (let index = 0; index < it.child.childNodes.length; index++) {
            queue.push({ child: it.child.childNodes[index], parent_id: it.child.id });
          }
        }
      }
    }
    return data;
  }

  static normalize(provided: Editor.Data.Node.Data) {
    const result = JSON.parse(JSON.stringify(EMPTY_REFS));
    const queue = [provided];

    if (!provided.id || !provided.parent_id) {
      throw new Error('Invalid Data!!!');
    }

    let element;
    while (queue.length) {
      element = queue.shift();
      if (element && element.type !== 'text') {
        if (element.type === 'tracked-delete' || element.type === 'tracked-insert') {
          if (element.properties && element.properties.element_reference) {
            result.tracked.push({
              elId: element.id,
              sug: element.properties.element_reference,
            });
          }
        }
        if (element.type === ELEMENTS.CommentElement.ELEMENT_TYPE) {
          if (element.properties && element.properties.element_reference) {
            result.comments.push(element.properties.element_reference);
          }
        }
        if (element.type === 'temp-comment') {
          if (element.properties && element.properties.element_reference) {
            result.tempComments.push(element.properties.element_reference);
          }
        }
        if (element.type === 'citation') {
          if (element.properties && element.properties.element_reference) {
            result.citations.push({
              cit: element.properties.element_reference,
              el: element.id,
            });
          }
        }
        if (element.type === 'note' && element.properties?.note_type === 'footnote') {
          if (element.properties && element.properties.element_reference) {
            result.fnt.push(element.properties.element_reference);
          }
        }
        if (element.type === 'note' && element.properties?.note_type === 'endnote') {
          if (element.properties && element.properties.element_reference) {
            result.ent.push(element.properties.element_reference);
          }
        }
        if (element.type === ELEMENTS.FieldElement.ELEMENT_TYPE) {
          if (element.properties && element.properties.t) {
            result.f.push({
              el: element.id,
              t: element.properties.t,
              cpt: element.properties.cpt,
              r: element.properties.r,
              f: element.properties.f,
            });
          }
        }
        if (element.type === ELEMENTS.SectionBreakElement.ELEMENT_TYPE) {
          if (element.properties) {
            result.br.push({
              id: element.id,
              t: 'Section',
              n_sct: element.properties.n_sct,
              sect: element.properties.sect,
            });
          }
        }

        if (element.childNodes) {
          queue.unshift(...element.childNodes);
        }
      }
    }
    let foundIndex;
    const tracked = result.tracked.reduce((prev: any, current: any) => {
      foundIndex = prev.findIndex((value: any) => value.sug === current.sug);
      if (foundIndex < 0) {
        prev.push({
          sug: current.sug,
          els: [current.elId],
        });
      } else {
        prev[foundIndex].els.push(current.elId);
      }
      return prev;
    }, []);
    provided.refs = {
      tracked,
      comments: uniq(result.comments),
      tempComments: uniq(result.tempComments),
      citations: uniq(result.citations),
      fnt: uniq(result.fnt),
      ent: uniq(result.ent),
      f: uniq(result.f),
      br: uniq(result.br),
    };
    provided.permissions = {};
    provided.approvedBy = [];
  }

  constructor(
    transport: Transport,
    id: Realtime.Core.RealtimeObjectId,
    undoManager?: Realtime.Core.UndoManager,
  ) {
    super(transport, id, 'nodes', undoManager);

    this.lockStatus = {};
    this.lockFailSafe = {};
  }

  get navigationData() {
    if (this._navigationData == null) {
      this._navigationData = this.buildContentFromData();
    }

    return this._navigationData;
  }

  private buildContentFromData(): Editor.Data.Node.NavigationData[] {
    const iteratorData: Editor.Data.Node.NavigationData[] = [];

    let workingData: Editor.Data.Node.NavigationData = {
      info: [],
      content: '',
    };

    const initialData = this.selectedData();

    if (!initialData) {
      return iteratorData;
    }

    const queue: { data: Editor.Data.Node; path: Editor.Selection.Path }[] = [
      { data: initialData, path: [] },
    ];

    while (queue.length) {
      const element = queue.shift();
      if (element) {
        const { data, path } = element;
        if (data.type === 'text' && data.content?.length) {
          // text node
          if (!workingData.content) {
            workingData.content = '';
          }

          workingData.info.push({
            contentOffsets: {
              start: workingData.content.length,
              end: workingData.content.length + data.content.length,
            },
            path: [...path],
            data: data,
          });
          workingData.content += data.content;
        } else if (!data.childNodes?.length) {
          if (workingData.content?.length) {
            iteratorData.push(workingData);
          }

          if (data.type === 'p') {
            // paragraph with no content
            iteratorData.push({
              info: [
                {
                  contentOffsets: { start: 0, end: 0 },
                  path: [...path],
                  data: data,
                },
              ],
              content: '',
            });
          } else if (!(data.type === 'tblc' && data.properties?.['head-id'])) {
            // node with no content
            iteratorData.push({
              info: [
                {
                  contentOffsets: { start: 0, end: 1 },
                  path: [...path],
                  data: data,
                },
              ],
              content: null,
            });
          }

          workingData = {
            info: [],
            content: '',
          };
        } else if (data.childNodes.length) {
          if (data.type === 'tblc') {
            if (data.properties?.['head-id']) {
              continue;
            }
          } else if (NodeUtils.BLOCK_TEXT_TYPES.includes(data.type) && data.id !== initialData.id) {
            // separate elements
            if (workingData.content?.length) {
              iteratorData.push(workingData);
            }

            workingData = {
              info: [],
              content: '',
            };
          }

          // node with child nodes
          for (let i = data.childNodes.length - 1; i >= 0; i--) {
            queue.unshift({
              data: data.childNodes[i],
              path: [...path, 'childNodes', i],
            });
          }
        }
      }
    }

    if (workingData.content?.length) {
      iteratorData.push(workingData);
    }

    return iteratorData;
  }

  get KEYS() {
    return NodeKeys;
  }

  create(data: Editor.Data.Node.Data): Promise<RealtimeObject<Editor.Data.Node.Data>> {
    NodeModel.normalize(data);
    return super.create(data);
  }

  get language() {
    return this.selectedData()?.lang;
  }

  get style() {
    const data = this.selectedData();
    return data?.properties?.s || data?.st;
  }

  get type() {
    return this.selectedData()?.type;
  }

  get tasks() {
    return this.selectedData()?.tasks;
  }

  get footNotes() {
    return this.selectedData()?.refs?.fnt || [];
  }

  get endNotes() {
    return this.selectedData()?.refs?.ent || [];
  }

  getStyleForElementId(elementId: string) {
    let path: (string | number)[] = [];
    if (this.get(['id']) !== elementId) {
      path = this.findPath('id', elementId);

      // remove path id
      path.pop();
    }

    let styleId = this.get([...path, this.KEYS.PROPERTIES, 's']);
    if (!styleId) {
      styleId = this.get([...path, 'st']);
    }

    return styleId;
  }

  protected getUndoableOps(ops: Realtime.Core.RealtimeOps): Realtime.Core.RealtimeOps {
    return ops.filter((op) => {
      return op.p[0] !== 'lock';
    });
  }

  protected getLockKeyForPath(path: Path = []): string {
    return `${path.slice(0, -1).join(':')}`;
  }

  // TODO: refactor
  protected setLock(path: Path = ['lock']) {
    const lockData = this.getLockObject(path);
    const key = this.getLockKeyForPath(path);
    if (lockData != null && lockData.lock) {
      this.lockStatus[key] = {
        lock: lockData.lock,
        lockExpiration: lockData.lockExpiration,
      };
      if (this.lockFailSafe[key]) {
        clearTimeout(this.lockFailSafe[key]);
      }

      const now = new Date();
      const expDate = new Date(this.lockStatus[key].lockExpiration);
      const dateDiff = expDate.getTime() - now.getTime();

      const operationContext: Realtime.Core.OperationContext = {
        nodeId: this.id,
      };

      this.lockFailSafe[key] = setTimeout(() => {
        if (this.lockStatus[key]) {
          operationContext.lock = false;
          // TODO:
          // this.emit('UNLOCK', operationContext);
        }
        this.lockStatus[key] = null;
      }, Math.max(500, dateDiff + 500));
    } else {
      if (this.lockFailSafe[key]) {
        clearTimeout(this.lockFailSafe[key]);
        this.lockFailSafe[key] = null;
      }
      this.lockStatus[key] = null;
    }
  }

  protected getLock(path: Path = ['lock']): string {
    return this.get(path);
  }

  getLockObject(path: Path = ['lock']): Editor.Data.Node.LockData | null {
    const lockData = this.getLock(path);
    if (lockData != null) {
      if (typeof lockData === 'string') {
        const [lock, lockExpiration] = lockData.split('|');
        return {
          lock,
          lockExpiration,
        };
      } else if (typeof lockData === 'boolean') {
        return {
          lock: `${lockData}`,
        };
      }
    }
    return null;
  }

  // private validateLockStatus(operationContext: OperationContext) {
  //   const { op } = operationContext;
  //   if (op) {
  //     const path: Path = op.p;
  //     const lockData: LockData | null = this.getLockObject(path);
  //     const key = this.getLockKeyForPath(path);
  //     if (lockData && op.od !== undefined && op.oi === undefined && this.lockStatus[key]) {
  //       // this is an unlock
  //       operationContext.lock = lockData.lock;
  //       this.emit('UNLOCK', operationContext);
  //     } else if (this.lockStatus[key] && lockData != null && lockData?.lock) {
  //       if (this.lockStatus[key].lock !== lockData.lock) {
  //         operationContext.lock = lockData.lock;
  //         operationContext.key = key;
  //         this.emit('LOCK', operationContext);
  //       }
  //     } else if (!this.lockStatus[key] && lockData != null && lockData?.lock) {
  //       operationContext.lock = lockData.lock;
  //       this.emit('LOCK', operationContext);
  //     } else {
  //       operationContext.lock = false;
  //       this.emit('UNLOCK', operationContext);
  //     }
  //     this.setLock(path);
  //   }
  // }

  handleLoad(): void {
    // TODO:
    // model loaded
    // version loaded

    this.setLock();
  }

  handlePreBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {}

  handleBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    logger.info('NODE operations ' + this.id, `source: ${source}`, ops);

    this.emit('UPDATED', this.data, ops, source);

    const length = ops.length;
    let op;

    const nodeRenderOperations = [];
    // filter operations
    for (let i = 0; i < length; i++) {
      op = ops[i];

      if (
        !source ||
        source === 'LOCAL_RENDER' ||
        source === 'LOCAL_RENDER_OLD' ||
        source === 'UNDO' ||
        source === 'REDO'
      ) {
        // local operations
        if (op.p && op.p.includes('approvedBy')) {
          // update on refs
          nodeRenderOperations.push(op);
        } else if (op.p && op.p.includes('refs')) {
          // update on refs
          if (op.p.includes('comments') || !isEqual(op?.od?.comments, op?.oi?.comments)) {
            this.emit('COMMENTS_CHANGED', {
              in: op.li,
              out: op.ld,
            });
          }
          if (
            op.p.includes('tempComments') ||
            !isEqual(op?.od?.tempComments, op?.oi?.tempComments)
          ) {
            this.emit('COMMENTS_CHANGED', {
              in: op.li,
              out: op.ld,
            });
          }
          if (op.p.includes('citations') || !isEqual(op?.od?.citations, op?.oi?.citations)) {
            this.emit('CITATIONS_CHANGED', {
              in: op.li,
              out: op.ld,
            });
          }
          if (
            op.p.includes('fnt') ||
            op.p.includes('ent') ||
            !isEqual(op?.od?.fnt, op?.oi?.fnt) ||
            !isEqual(op?.od?.ent, op?.oi?.ent)
          ) {
            this.emit('NOTES_CHANGED');
          }
          if (op.p.includes('tracked') || !isEqual(op?.od?.tracked, op?.oi?.tracked)) {
            this.emit('SUGGESTIONS_CHANGED', {
              in: op.li,
              out: op.ld,
            });
          }
        } else if (op.p && op.p.includes('permissions')) {
          // review this as the node data should be refreshed from the
          // structure, ex loss of access
          this.forceUpdate();
          this.emit('PERMISSIONS_UPDATED');
        } else if (op.p && op.p.includes('tasks')) {
          this.emit('UPDATED_TASKS');
          nodeRenderOperations.push(op);
        } else if (op.p.includes('lock')) {
          this.setLock();
          nodeRenderOperations.push(op);
        } else {
          this._navigationData = null;
          nodeRenderOperations.push(op);
        }
      } else {
        // remote operations
        if (op.p) {
          if (op.p.includes('lock')) {
            this.setLock();
            nodeRenderOperations.push(op);
          } else if (op.p.includes('tasks')) {
            this.emit('UPDATED_TASKS');
            nodeRenderOperations.push(op);
          } else if (op.p[0] === 'type' || op.p[0] === 'st') {
            nodeRenderOperations.push(op);
          } else {
            this._navigationData = null;
          }
        }
      }
    }

    if (nodeRenderOperations.length > 0) {
      this.emit('UPDATE_RENDER', nodeRenderOperations);
    }
  }

  handlePreOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {}

  handleOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {}

  getSuggestionRefs(): any[] {
    const foundSugg = [];
    const queue = [this.selectedData()];
    let element;
    while (queue.length) {
      element = queue.shift();
      if (element && element.type !== 'text') {
        if (element.type === 'tracked-delete' || element.type === 'tracked-insert') {
          if (element.properties && element.properties.element_reference) {
            foundSugg.push({
              elId: element.id,
              sug: element.properties.element_reference,
            });
          }
        }
        if (element.childNodes) {
          queue.unshift(...element.childNodes);
        }
      }
    }
    let foundIndex;
    const tracked = foundSugg.reduce((prev: any[], current: any) => {
      foundIndex = prev.findIndex((value: any) => value.sug === current.sug);
      if (foundIndex < 0) {
        prev.push({
          sug: current.sug,
          els: [current.elId],
        });
      } else {
        prev[foundIndex].els.push(current.elId);
      }
      return prev;
    }, []);
    return tracked;
  }

  getCommentsRefs(): any[] {
    const foundComments = [];
    const queue = [this.selectedData()];
    let element;
    while (queue.length) {
      element = queue.shift();
      if (element && element.type !== 'text') {
        if (element.type === ELEMENTS.CommentElement.ELEMENT_TYPE) {
          if (element.properties && element.properties.element_reference) {
            foundComments.push({
              elId: element.id,
              ref: element.properties.element_reference,
            });
          }
        }
        if (element.childNodes) {
          queue.unshift(...element.childNodes);
        }
      }
    }
    let foundIndex;
    const comments = foundComments.reduce((prev: any[], current: any) => {
      foundIndex = prev.findIndex((value: any) => value.ref === current.ref);
      if (foundIndex < 0) {
        prev.push({
          ref: current.ref,
          els: [current.elId],
        });
      } else {
        prev[foundIndex].els.push(current.elId);
      }
      return prev;
    }, []);
    return comments;
  }

  getTempCommentsRefs(): any[] {
    const foundComments = [];
    const queue = [this.selectedData()];
    let element;
    while (queue.length) {
      element = queue.shift();
      if (element && element.type !== 'text') {
        if (element.type === ELEMENTS.TemporaryComment.ELEMENT_TYPE) {
          if (element.properties && element.properties.element_reference) {
            foundComments.push({
              elId: element.id,
              ref: element.properties.element_reference,
              author: element.properties.author,
            });
          }
        }
        if (element.childNodes) {
          queue.unshift(...element.childNodes);
        }
      }
    }
    let foundIndex;
    const comments = foundComments.reduce((prev: any[], current: any) => {
      foundIndex = prev.findIndex((value: any) => value.ref === current.ref);
      if (foundIndex < 0) {
        prev.push({
          ref: current.ref,
          els: [current.elId],
          author: current.author,
        });
      } else {
        prev[foundIndex].els.push(current.elId);
      }
      return prev;
    }, []);
    return comments;
  }

  getNotesLocations(): any {
    const foundFootnotes: any = {};
    const queue = [this.selectedData()];
    let element: any;
    while (queue.length) {
      element = queue.shift();
      if (element?.type === 'note') {
        if (element.properties && element.properties.element_reference) {
          foundFootnotes[element.properties.element_reference] = {
            level0: this.id,
            note: element.id,
          };
        }
      }
      if (element?.childNodes) {
        queue.unshift(...element.childNodes);
      }
    }
    return foundFootnotes;
  }

  isReadonly() {
    return this.selectedData()?.readonly;
  }

  isApproved() {
    return this.selectedData()?.approvedBy.length > 0;
  }

  async addTask(taskId: string): Promise<RealtimeObject<Editor.Data.Node.Data>> {
    const tasks = this.selectedData()?.tasks;
    if (!tasks) {
      return this.set([this.KEYS.TASKS], [taskId]);
    }
    return this.listInsert(['tasks', tasks.length], taskId);
  }

  async removeTask(taskId: string): Promise<RealtimeObject<Editor.Data.Node.Data>> {
    const tasks = this.selectedData()?.tasks;
    const index = tasks?.indexOf(taskId);
    if (tasks != null && index != null && index >= 0) {
      return this.listDelete(['tasks', index]);
    }
    return Promise.resolve(this);
  }

  clearLeftIndentation(elementId: string): Promise<RealtimeObject<Editor.Data.Node.Data>> {
    const ops = [];
    let path: (string | number)[] = [];

    if (this.get(['id']) !== elementId) {
      path = this.findPath('id', elementId);

      // remove path id
      path.pop();
    }

    const ind = this.get([...path, 'properties', 'ind']);
    if (ind != null && ind.l != null) {
      if (ind.r != null) {
        ops.push({
          od: ind.l,
          p: [...path, 'properties', 'ind', 'l'],
        });
      } else {
        ops.push({
          od: ind,
          p: [...path, 'properties', 'ind'],
        });
      }
    }

    const spInd = this.get([...path, 'properties', 'sp_ind']);
    if (spInd != null) {
      ops.push({
        od: spInd,
        p: [...path, 'properties', 'sp_ind'],
      });
    }

    if (ops.length > 0) {
      return this.apply(ops, { source: 'LOCAL_RENDER' });
    }

    return Promise.resolve(this);
  }

  getChildDataById(elementId: string): Editor.Data.Node.Data {
    let data: Editor.Data.Node.Data | null;

    if (!elementId || this.get(['id']) === elementId) {
      data = this.selectedData();
    } else {
      const path = this.findPath('id', elementId);
      // remove path id
      path.pop();

      data = this.get(path);
    }

    data = omit(
      {
        ...data,
      },
      ['commentRefs', 'permissions', 'approvedBy', 'refs', 'lang'],
    ) as Editor.Data.Node.Data;

    return data;
  }

  getChildInfoById(elementId: string): {
    data: Editor.Data.Node.Data;
    path: Realtime.Core.RealtimePath;
  } {
    let data: Editor.Data.Node.Data | null;

    let path: Realtime.Core.RealtimePath = [];
    if (!elementId || this.get(['id']) === elementId) {
      path = [];
      data = this.selectedData();
    } else {
      path = this.findPathToChild(elementId);

      data = this.get(path);
    }

    data = omit(
      {
        ...data,
      },
      ['commentRefs', 'permissions', 'approvedBy', 'refs', 'lang'],
    ) as Editor.Data.Node.Data;

    return { data, path };
  }

  getChildDataByPath(path?: Path): Editor.Data.Node.Data {
    let data: Editor.Data.Node.Data | null;

    if (!path || !path.length) {
      data = this.selectedData();
    } else {
      data = this.get(path);
    }

    data = omit(
      {
        ...data,
      },
      ['commentRefs', 'permissions', 'approvedBy', 'refs', 'lang'],
    ) as Editor.Data.Node.Data;

    return data;
  }

  findPathToChild(id: string) {
    const path = this.findPath(this.KEYS.ID, id);
    path.pop(); // remove id path
    return path;
  }

  private findTabIndex(tabs: Editor.Data.TabStop[], newTab: Editor.Data.TabStop) {
    if (!tabs.length || newTab.v < tabs[0].v) {
      return this.tansformInsertionIndex(0);
    }

    if (newTab.v === tabs[0].v) {
      return 0;
    }

    if (newTab.v > tabs[tabs.length - 1].v) {
      return this.tansformInsertionIndex(tabs.length);
    }

    for (let i = 1; i < tabs.length; i++) {
      if (newTab.v === tabs[i].v) {
        return i;
      }
      if (newTab.v > tabs[i - 1].v && newTab.v < tabs[i].v) {
        return this.tansformInsertionIndex(i);
      }
    }
  }

  private tansformInsertionIndex(ind: number) {
    return -(ind + 1);
  }

  updateCustomTabs(tabs: Editor.Data.TabStop[]) {
    const props = this.selectedData()?.properties;
    if (!props) {
      return this.set(
        [this.KEYS.PROPERTIES],
        { tabs: tabs },
        {
          source: 'LOCAL_RENDER',
        },
      );
    } else {
      return this.set([this.KEYS.PROPERTIES, 'tabs'], tabs, {
        source: 'LOCAL_RENDER',
      });
    }
  }

  addCustomTab(tab: Editor.Data.TabStop) {
    const props = this.selectedData()?.properties;
    if (!props) {
      return this.set(
        [this.KEYS.PROPERTIES],
        { tabs: [tab] },
        {
          source: 'LOCAL_RENDER',
        },
      );
    } else if (!props.tabs) {
      return this.set([this.KEYS.PROPERTIES, 'tabs'], [tab], {
        source: 'LOCAL_RENDER',
      });
    }

    const ind = this.findTabIndex(props.tabs, tab);
    if (ind === undefined) {
      return;
    }

    if (ind >= 0) {
      return this.listReplace([this.KEYS.PROPERTIES, 'tabs', ind], tab, {
        source: 'LOCAL_RENDER',
      });
    } else {
      return this.listInsert(
        [this.KEYS.PROPERTIES, 'tabs', this.tansformInsertionIndex(ind)],
        tab,
        {
          source: 'LOCAL_RENDER',
        },
      );
    }
  }

  editCustomTabStop(tab: Editor.Data.TabStop, value: Editor.Data.TabStop['v']) {
    if (value === tab.v) {
      return;
    }

    const props = this.selectedData()?.properties;
    if (!props?.tabs) {
      return;
    }

    if (!this.deleteCustomTab(tab)) {
      return;
    }

    return this.addCustomTab({ ...tab, v: value });
  }

  private editCustomTabProp<p extends keyof Editor.Data.TabStop>(
    tab: Editor.Data.TabStop,
    prop: p,
    value: Editor.Data.TabStop[p],
  ) {
    if (value === tab[prop]) {
      return;
    }

    const props = this.selectedData()?.properties;
    if (!props?.tabs) {
      return;
    }

    const ind = this.findTabIndex(props.tabs, tab);
    if (ind === undefined || ind < 0) {
      return;
    }

    return this.set([this.KEYS.PROPERTIES, 'tabs', ind, prop], value, {
      source: 'LOCAL_RENDER',
    });
  }

  editCustomTabLeader(tab: Editor.Data.TabStop, value: Editor.Data.TabStop['l']) {
    return this.editCustomTabProp(tab, 'l', value);
  }

  editCustomTabAlignment(tab: Editor.Data.TabStop, value: Editor.Data.TabStop['t']) {
    return this.editCustomTabProp(tab, 't', value);
  }

  deleteCustomTab(tab: Editor.Data.TabStop) {
    const props = this.selectedData()?.properties;
    if (!props?.tabs) {
      return;
    }

    const ind = this.findTabIndex(props.tabs, tab);
    if (ind === undefined || ind < 0) {
      return;
    }

    return this.listDelete([this.KEYS.PROPERTIES, 'tabs', ind], {
      source: 'LOCAL_RENDER',
    });
  }

  isType(type: Editor.Elements.ElementTypesType) {
    return this.selectedData()?.type === type;
  }
}
