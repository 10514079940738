import { ReduxInterface } from 'Editor/services';
import ActionContext from 'Editor/services/EditionManager/EditionModes/_Common/models/ActionContext';
import EditorManager from '../EditorManager';
import {
  TableElement,
  TableCellElement,
  FieldElement,
  HyperlinkElement,
  CommentElement,
  TemporaryCommentElement,
  ReferencesSectionElement,
  TrackDeleteElement,
  TrackInsertElement,
  RENDER_MODES,
  BlockViewModel,
  NoteElement,
  CitationsGroupElement,
  BaseBlockElement,
  EquationElement,
  SectionElement,
  PageElement,
  FigureElement,
  ParagraphElement,
} from '../VisualizerManager';
import { DocumentStyles, ListStyles, InlineStyles, TableStyles, ImageStyles } from './';
import { Logger } from '_common/services';
import { SelectionUtils } from '_common/DoDOCSelection';

import { ELEMENTS } from 'Editor/services/consts';
import { BaseTypedEmitter } from '_common/services/Realtime';
import { EditorRange, EditorSelectionUtils, JsonRange } from '../_Common/Selection';
import {
  DocumentStyleData,
  DocumentStyleProperties,
} from '../DataManager/models/Structure/Structure.types';
import { EditorDOMElements, EditorDOMUtils } from '../_Common/DOM';

export class StylesHandler extends BaseTypedEmitter {
  editorContext: Editor.Context;
  stylesContext: Editor.Styles.Context;
  timeouts: Editor.Timeouts;

  constructor(editorContext: Editor.Context) {
    super();
    this.editorContext = editorContext;
    this.stylesContext = {};
    this.timeouts = {};

    this.stylesContext.inlineStyles = new InlineStyles(editorContext, this.stylesContext);
    this.stylesContext.documentStyles = new DocumentStyles(editorContext, this.stylesContext);
    this.stylesContext.listStyles = new ListStyles(editorContext, this.stylesContext);
    this.stylesContext.tableStyles = new TableStyles(editorContext, this.stylesContext);
    this.stylesContext.imageStyles = new ImageStyles(editorContext, this.stylesContext);

    this.stylesContext.askUserAboutThis = this.askUserAboutThis.bind(this);

    this.onSelectionUpdate = this.onSelectionUpdate.bind(this);
  }

  start() {
    this.stylesContext.inlineStyles?.start();
    this.stylesContext.documentStyles?.start();
    this.stylesContext.listStyles?.start();

    this.editorContext.DataManager?.on('SELECTION_UPDATED', this.onSelectionUpdate);
  }

  destroy() {
    this.editorContext.DataManager?.off('SELECTION_UPDATED', this.onSelectionUpdate);

    if (this.stylesContext.inlineStyles) {
      this.stylesContext.inlineStyles.destroy();
    }

    if (this.stylesContext.documentStyles) {
      this.stylesContext.documentStyles.destroy();
    }

    if (this.stylesContext.listStyles) {
      this.stylesContext.listStyles.destroy();
    }

    super.destroy();
  }

  askUserAboutThis() {
    if (ReduxInterface.isEditorTrackingStateOn()) {
      // eslint-disable-next-line no-alert
      return window.confirm('This action will not be tracked. Proceed?');
    }
    return true;
  }

  onSelectionUpdate(
    ranges: Editor.Selection.RangeData[],
    source: Realtime.Core.RealtimeSourceType,
  ) {
    if (source === 'LOCAL_RENDER' || source === 'LOCAL_RENDER_OLD' || source === true) {
      this.scheduleCheckStyles();
    }
  }

  scheduleCheckStyles() {
    if (this.timeouts.scheduleCheckStyles) {
      clearTimeout(this.timeouts.scheduleCheckStyles);
    }

    this.timeouts.scheduleCheckStyles = setTimeout(() => {
      this.stylesContext.documentStyles?.checkDocumentStyleStatusOnSelection();
      this.stylesContext.inlineStyles?.checkInlineStylesStatusOnSelection();
      this.checkSelectionContentStatus();
    }, 0);
  }

  // ------------------------------------------------------
  //                  Inline Styles
  // ------------------------------------------------------
  buildAttributeApplier(attribute: Editor.Styles.Styles, value: any) {
    return this.stylesContext.inlineStyles?.buildAttributeApplier(attribute, value);
  }

  getStyleAppliersFromElement(nodeElement: Node, appliersToExtract: string[]) {
    return this.stylesContext.inlineStyles?.getStyleAppliersFromElement(
      nodeElement as HTMLElement,
      appliersToExtract,
    );
  }

  addArrayAppliersToPendingStyles(options: any) {
    return this.stylesContext.inlineStyles?.addArrayAppliersToPendingStyles(options);
  }

  areStylesToApply() {
    return this.stylesContext.inlineStyles?.areStylesToApply();
  }

  togglePendingStyles() {
    return this.stylesContext.inlineStyles?.togglePendingStyles();
  }

  toggleStyleAttribute(attribute: Editor.Styles.Styles, value: any, options = {}) {
    return this.stylesContext.inlineStyles?.toggleStyleAttribute(attribute, value, options);
  }

  clearFormatting() {
    this.stylesContext.inlineStyles?.clearFormatting({});
  }

  getDocumentColors() {
    return this.stylesContext.inlineStyles?.getDocumentColors();
  }

  async increaseIndent(actionContext?: Editor.Edition.OldActionContext, outdent = false) {
    // return this.stylesContext.inlineStyles?.increaseIndent();

    if (!this.askUserAboutThis()) {
      return;
    }

    if (this.editorContext.navigationManager?.isMarkerRendered()) {
      this.editorContext.visualizerManager?.selection.stopSelectionTracker();

      try {
        this.editorContext.navigationManager?.scrollIntoSelection();

        if (this.editorContext.selectionManager?.isSelectionInPage()) {
          EditorSelectionUtils.fixSelection();
        }

        EditorManager.getInstance().removePasteOptions();

        if (!actionContext) {
          actionContext = new ActionContext();
        }

        if (this.editorContext.selectionManager) {
          const stylableElements = EditorRange.splitRangeByBlocks(undefined, [
            ...EditorDOMElements.BLOCK_TEXT_ELEMENTS,
            ELEMENTS.FigureElement.TAG, // legacy support
          ]);

          let i;
          for (i = 0; i < stylableElements.length; i++) {
            const block = stylableElements[i].block;
            const range = stylableElements[i].range;

            if (
              block instanceof HTMLElement &&
              (EditorDOMUtils.isClosestTextElementEditable(block) || block instanceof FigureElement)
            ) {
              if (this.editorContext.DataManager?.numbering.isListElement(block.id)) {
                await this.stylesContext.listStyles?.indentList(
                  actionContext,
                  block,
                  range,
                  outdent,
                );
              } else if (block instanceof ParagraphElement) {
                if (outdent) {
                  block.outdent();
                  actionContext.addChangeUpdatedNode(block);
                } else {
                  block.indent();
                  actionContext.addChangeUpdatedNode(block);
                }
              }
            }
          }
        }

        this.editorContext.visualizerManager?.selection.triggerSelectionChanged();

        //@ts-expect-error
        this.editorContext.changeTracker?.saveActionChanges(actionContext);
      } catch (error) {
        Logger.captureException(error);
      } finally {
        this.editorContext.visualizerManager?.selection.debounceStartSelectionTracker();
      }
    }
  }

  decreaseIndent(actionContext?: Editor.Edition.OldActionContext) {
    return this.increaseIndent(actionContext, true);
  }

  indentationCurrentSelection(indentation: Editor.Elements.IndentationProperties) {
    return this.stylesContext.inlineStyles?.indentationCurrentSelection(indentation);
  }

  applyParagraphBackgroundColor(value: string | null) {
    return this.stylesContext.inlineStyles?.setParagraphBackgroundColor(value);
  }

  lineSpaceCurrentSelection(spacing: Editor.Elements.SpacingProperties) {
    return this.stylesContext.inlineStyles?.lineSpaceCurrentSelection(spacing);
  }

  setLinePaginationProperties(properties: Editor.Elements.PaginationProperties) {
    return this.stylesContext.inlineStyles?.setLinePaginationProperties(properties);
  }

  alignCurrentSelection(styleProps: Editor.Elements.TextAlignProperties) {
    return this.stylesContext.inlineStyles?.alignCurrentSelection(styleProps);
  }

  // ------------------------------------------------------
  //                  Document Styles
  // ------------------------------------------------------

  async createNewDocumentStyleFromId(baseStyleKey: string) {
    return this.stylesContext.documentStyles?.createNewDocumentStyleFromId(baseStyleKey);
  }

  createNewDocumentStyle(id: string, newStyle: Partial<DocumentStyleData>) {
    return this.stylesContext.documentStyles?.createNewDocumentStyle(id, newStyle);
  }

  async applyDocumentStyleToSelection(styleKey: string) {
    return this.stylesContext.documentStyles?.applyDocumentStyleToSelection(styleKey);
  }

  async updateDocumentStyleFromSelection(styleId: string) {
    return this.stylesContext.documentStyles?.updateDocumentStyleFromSelection(styleId);
  }

  renameDocumentStyle(styleId: string, newName: string) {
    return this.stylesContext.documentStyles?.renameStyle(styleId, newName);
  }

  deleteDocumentStyle(styleId: string) {
    return this.stylesContext.documentStyles?.deleteDocumentStyle(styleId);
  }

  resetTemplateStyle(styleId: string) {
    return this.stylesContext.documentStyles?.resetTemplateStyle(styleId);
  }

  checkIfStyleNameExist(name: string) {
    return this.stylesContext.documentStyles?.getStyleByName(name);
  }

  createNewDocumentStyleFromWord(style: { n: string; p: DocumentStyleProperties; t: string }) {
    return this.stylesContext.documentStyles?.createNewDocumentStyleFromWord(style);
  }

  // ------------------------------------------------------
  //                  List Styles
  // ------------------------------------------------------~
  //@ts-expect-error add type when refactoring styles
  toggleListStyleToSelection(styleId) {
    this.stylesContext.listStyles?.toggleListStyleToSelection(styleId);
  }

  removeListStyleFromBlock(
    actionContext: Editor.Edition.OldActionContext,
    blockElement: HTMLElement,
  ) {
    this.stylesContext.listStyles?.removeListStyleFromBlock(actionContext, blockElement);
  }

  // ------------------------------------------------------
  //                  Table Styles
  // ------------------------------------------------------

  getSelectedTableProperties() {
    if (this.stylesContext.tableStyles) {
      return this.stylesContext.tableStyles?.getSelectedTableProperties();
    }
    return null;
  }

  deselectTableCells(e: KeyboardEvent, checkSelection = true) {
    return this.stylesContext.tableStyles?.deselectTableCells(e, checkSelection);
  }

  // ------------------------------------------------------
  //                  Image Styles
  // ------------------------------------------------------
  getSelectedImageProperties(): Editor.Styles.ImageProperties | null {
    if (this.stylesContext.imageStyles) {
      return this.stylesContext.imageStyles?.getSelectedImageProperties();
    }
    return null;
  }

  // ------------------------------------------------------
  //                  Check Content Status
  // ------------------------------------------------------
  /**
   * check selected content status
   */
  // TODO temp refactor, refactor this function when refactoring the styles
  private checkSelectionContentStatus() {
    const status: Editor.Styles.ContentStatus = {
      TEXT: true,
      IMAGE: null,
      TABLE: null,
      EQUATION: null,
      EDITABLE: true,
      COMMENT: [],
      TRACKED: [],
      TASK: false,
      FIELD: false,
      NOTE: false,
      LINK: false,
      REFSECTION: false,
      BLOCK_IDS: [],
      LANGUAGES: {},
      LIST: false,
      CITATION: false,
      TABULATIONS: [],
      COLLAPSED: true,
      SECTION: null,
    };

    const previousStatus = ReduxInterface.getEditorState().status.selection;

    const range = EditorSelectionUtils.getRange();

    if (range) {
      status.COLLAPSED = range.collapsed;

      // get nodes to check
      const nodesToCheck = range.getNodes();

      let blockNode:
        | BaseBlockElement
        | TableElement
        | TrackInsertElement
        | TrackDeleteElement
        | null = null;

      let node = range.commonAncestorContainer;
      let pageNode = EditorDOMUtils.getContentContainer(node);
      while (node.parentNode && node !== pageNode) {
        if (node instanceof Element) {
          if (
            node.parentNode === pageNode &&
            (node instanceof BaseBlockElement ||
              node instanceof TableElement ||
              node instanceof TrackInsertElement ||
              node instanceof TrackDeleteElement)
          ) {
            blockNode = node;
          }

          if (!nodesToCheck.includes(node)) {
            nodesToCheck.push(node);
          }
        }

        node = node.parentNode;
      }

      // block node check
      if (blockNode) {
        status.BLOCK_TAG = blockNode.nodeName;

        // check if node is editable
        if (
          (blockNode instanceof TrackInsertElement || blockNode instanceof TrackDeleteElement) &&
          (blockNode.firstChild instanceof BaseBlockElement ||
            blockNode.firstChild instanceof TableElement)
        ) {
          blockNode = blockNode.firstChild;
        }

        if (!EditorDOMUtils.isClosestBlockNodeEditable(blockNode)) {
          status.EDITABLE = false;
        }

        if (blockNode instanceof ReferencesSectionElement) {
          blockNode.select();
          status.REFSECTION = {
            id: blockNode.id,
          };
        }

        // check task
        if (
          (blockNode instanceof BaseBlockElement || blockNode instanceof TableElement) &&
          blockNode?.hasTasks?.()
        ) {
          const tasks = blockNode.getTasks() as string[];
          if (tasks?.length > 0) {
            status.TASK = {
              blockId: blockNode.id,
              tasks,
            };
            if (
              status.TASK &&
              JSON.stringify(previousStatus.TASK) !== JSON.stringify(status.TASK)
            ) {
              this.scheduleSelectTask(status.TASK);
            }
          }
        }
        if (
          previousStatus.TASK &&
          JSON.stringify(previousStatus.TASK) !== JSON.stringify(status.TASK)
        ) {
          this.sheduleDismissTask(previousStatus.TASK);
        }

        if (this.editorContext.DataManager) {
          const listId = this.editorContext.DataManager.numbering.getListIdFromBlock(blockNode.id);
          if (listId) {
            status.LIST = {
              id: listId,
              style: this.editorContext.DataManager.numbering.getStyleIdForList(listId),
            };
          }
        }
      }

      // check closest table elements
      const closestTable = EditorDOMUtils.closest(range.commonAncestorContainer, [
        ELEMENTS.TableElement.TAG,
      ]);
      if (closestTable && closestTable instanceof TableElement) {
        const startTD = EditorDOMUtils.closest(range.startContainer, ELEMENTS.TableCellElement.TAG);
        const endTD = EditorDOMUtils.closest(range.endContainer, ELEMENTS.TableCellElement.TAG);

        if (startTD instanceof TableCellElement && endTD instanceof TableCellElement) {
          closestTable.selectionChanged(startTD, endTD);
        }

        const selectedCells = closestTable.getSelectedCellsInfo?.() || [];
        const selectedRows = closestTable.getSelectedRows();

        if (selectedCells.length === 0) {
          const closestCell = EditorDOMUtils.closest(range.commonAncestorContainer, [
            ELEMENTS.TableCellElement.TAG,
          ]);
          if (closestCell && closestCell instanceof TableCellElement) {
            selectedCells.push(closestCell.getCellInfo());
          }
        }

        status.TABLE = {
          id: closestTable.id,
          selectedCells: selectedCells,
          rows: { fromStart: selectedRows[0]?.id === closestTable.tBodies[0].rows[0]?.id },
        };
      }

      // check closest figure elements
      const closestFigure = EditorDOMUtils.closest(range.commonAncestorContainer, [
        ELEMENTS.FigureElement.TAG,
        ELEMENTS.ImageElement.TAG,
      ]);
      if (closestFigure instanceof HTMLElement) {
        status.IMAGE = {
          id: closestFigure.id,
        };
        status.TEXT = false;

        if (status.BLOCK_TAG !== ELEMENTS.ApprovedElement.TAG) {
          this.editorContext.visualizerManager
            ?.getWidgetsManager()
            ?.addWidget('resizable', closestFigure, {});
          this.editorContext.visualizerManager
            ?.getWidgetsManager()
            ?.addWidget('imageOptions', closestFigure, {});
        }
      }

      // iterate through nodes to check
      const suggestionRefsFound: string[] = [];
      const commentRefsFound: string[] = [];
      let element;
      for (let i = 0; i < nodesToCheck.length; i++) {
        element = nodesToCheck[i];
        if (
          element instanceof Element &&
          !(element instanceof PageElement) &&
          (element.parentNode === EditorDOMUtils.getContentContainer(element) ||
            element.parentElement instanceof SectionElement)
        ) {
          status.BLOCK_IDS.push(element.id);
        }

        if (range.collapsed) {
          // collapsed range only

          // check field elements
          if (!(blockNode instanceof ReferencesSectionElement) && element instanceof FieldElement) {
            status.FIELD = {
              type: element.fieldType,
              id: element.id,
            };
            if (element.fieldType === ELEMENTS.FieldElement.TYPES.CAPTION) {
              status.FIELD.label = element.fieldLabel;
            }
            element.selectField();
          }

          //check link elements
          if (element instanceof HyperlinkElement) {
            status.LINK = {
              id: element.id,
            };
            element.select();
          }
        }

        // check for imagem-element or equation-element
        if (element instanceof EquationElement) {
          if (status.BLOCK_TAG !== ELEMENTS.ApprovedElement.TAG) {
            status.EQUATION = {
              id: element.id,
            };
            this.editorContext.visualizerManager
              ?.getWidgetsManager()
              ?.addWidget('equationOptions', element, {});
          } else {
            status.EQUATION = null;
          }
        }

        // notes
        if (element instanceof NoteElement) {
          status.NOTE = { id: element.id, type: element.type };
        }

        // citations
        if (element instanceof CitationsGroupElement) {
          status.CITATION = { id: element.id };
        }

        // check comments
        if (element instanceof CommentElement || element instanceof TemporaryCommentElement) {
          const ref = element.getAttribute('element_reference');
          if (ref && !commentRefsFound.includes(ref)) {
            commentRefsFound.push(ref);

            status.COMMENT.push({
              tag: element.tagName,
              ref,
            });

            if (
              blockNode &&
              element instanceof TemporaryCommentElement &&
              !element.hidden &&
              this.editorContext.DataManager?.users.isLoggedUser(element.getAuthor?.() || '')
            ) {
              ReduxInterface.openTemporaryCommentCard({
                reference: ref,
                level0: blockNode.id,
              });
            }
          }
        }

        // check tracked actions
        if (
          (element instanceof TrackInsertElement || element instanceof TrackDeleteElement) &&
          !element.hidden
        ) {
          const ref = element.getAttribute('element_reference');
          if (ref && !suggestionRefsFound.includes(ref)) {
            suggestionRefsFound.push(ref);

            status.TRACKED.push({
              tag: element.tagName,
              ref,
            });
          }
        }
      }

      if (status.BLOCK_IDS.length) {
        status.LANGUAGES = this.editorContext.DataManager?.nodes.getLanguageForNodes(
          status.BLOCK_IDS,
        );

        const jsonRange = JsonRange.buildFromDOMRange(range);
        const block = SelectionUtils.isSelctionBackwards(SelectionUtils.getSelection())
          ? jsonRange.start.b
          : jsonRange.end.b;

        const viewModel = this.editorContext.visualizerManager?.getViewModelById(block);
        if (viewModel && viewModel instanceof BlockViewModel) {
          let tabs: Editor.Data.TabStop[] = [];
          const view = viewModel.getRootView();
          if (view) {
            tabs = this.editorContext.visualizerManager?.getTabStops(view) || [];
          }
          if (tabs) {
            for (let i = 0; i < tabs.length; i++) {
              status.TABULATIONS.push({
                leader: tabs[i].l,
                type: tabs[i].t,
                value: tabs[i].v,
              });
            }
          }
        }

        const section = this.editorContext.DataManager?.sections.getSectionOfBlock(block);
        if (section) {
          const attributes = this.editorContext.DataManager?.sections.getSectionAttributes(section);
          if (attributes) {
            status.SECTION = {
              id: section,
              properties: attributes,
              numberOfSections: this.editorContext.DataManager?.sections?.numberOfSections || 0,
            };
          }
        }
      }

      // select comment and suggestion refs found
      this.scheduleSelectElements([...commentRefsFound, ...suggestionRefsFound]);

      // COMPARE STATES

      //close image widgets
      if (previousStatus.IMAGE?.id && status.IMAGE?.id !== previousStatus.IMAGE?.id) {
        const prevImage = document.getElementById(previousStatus.IMAGE?.id);
        if (
          prevImage &&
          (EditorDOMElements.isFigureElement(prevImage) ||
            EditorDOMElements.isImageElement(prevImage))
        ) {
          this.editorContext.visualizerManager
            ?.getWidgetsManager()
            ?.removeWidget('resizable', prevImage.id);
          this.editorContext.visualizerManager
            ?.getWidgetsManager()
            ?.removeWidget('imageOptions', prevImage.id);
        }
      }

      // close euqation widgets
      if (previousStatus.EQUATION?.id && status.EQUATION?.id !== previousStatus.EQUATION?.id) {
        const prevEquation = document.getElementById(previousStatus.EQUATION?.id);

        if (EditorDOMElements.isEquationElement(prevEquation)) {
          this.editorContext.visualizerManager
            ?.getWidgetsManager()
            ?.removeWidget('equationOptions', prevEquation.id);
        }
      }

      // close table widgets
      if (previousStatus.TABLE?.id && status.TABLE?.id !== previousStatus.TABLE?.id) {
        const prevTable = document.getElementById(previousStatus.TABLE?.id);
        if (prevTable && prevTable instanceof TableElement) {
          prevTable.removeTableOptions();
        }
      }

      // deleselect ref section
      if (
        previousStatus.REFSECTION != null &&
        previousStatus.REFSECTION !== false &&
        (!status.REFSECTION || previousStatus.REFSECTION.id !== status.REFSECTION.id)
      ) {
        const selectedRefSection = document.getElementById(previousStatus.REFSECTION.id);
        if (selectedRefSection && selectedRefSection instanceof ReferencesSectionElement) {
          selectedRefSection.deselect?.();
        }
      }

      // deleselect other fields
      if (
        previousStatus.FIELD != null &&
        previousStatus.FIELD !== false &&
        typeof status.FIELD !== 'boolean' &&
        previousStatus.FIELD.id !== status.FIELD.id
      ) {
        const selectedField = document.getElementById(previousStatus.FIELD.id);
        if (selectedField && selectedField instanceof FieldElement) {
          selectedField.deselectField?.();
        }
      }

      if (
        previousStatus.LINK != null &&
        previousStatus.LINK !== false &&
        typeof status.LINK !== 'boolean' &&
        previousStatus.LINK.id !== status.LINK.id
      ) {
        const link = document.getElementById(previousStatus.LINK.id);
        if (link && link instanceof HyperlinkElement) {
          link.deselect?.();
        }
      }

      const elementsToDeselect = [];

      // deselect comments
      if (previousStatus.COMMENT.length > 0) {
        elementsToDeselect.push(
          ...previousStatus.COMMENT.filter((ref) => !(commentRefsFound.indexOf(ref.ref) > -1)),
        );
      }

      // deselect tracked actions
      if (previousStatus.TRACKED.length !== 0) {
        elementsToDeselect.push(
          ...previousStatus.TRACKED.filter((ref) => !(suggestionRefsFound.indexOf(ref.ref) > -1)),
        );
      }

      this.scheduleDeselectElements(elementsToDeselect);

      this.scheduleSetSelectionStatus(status);
    }
  }

  getElementRefQuery(refs: string[]) {
    return refs.reduce((query, ref) => {
      if (query === '') {
        query += `[element_reference="${ref}"]`;
      } else {
        query += `,[element_reference="${ref}"]`;
      }
      return query;
    }, '');
  }

  scheduleSelectElements(refsToSelect: string[] = []) {
    if (
      refsToSelect.length > 0 &&
      this.editorContext.visualizerManager?.getRenderMode() === RENDER_MODES.BASIC
    ) {
      if (this.timeouts.selectComment) {
        clearTimeout(this.timeouts.selectComment);
      }

      this.timeouts.selectComment = setTimeout(() => {
        const query = this.getElementRefQuery(refsToSelect);

        const elements = document.querySelectorAll(query);

        for (let i = 0; i < elements.length; i++) {
          const element = elements.item(i);
          if (
            element instanceof CommentElement ||
            element instanceof TemporaryCommentElement ||
            element instanceof TrackDeleteElement ||
            element instanceof TrackInsertElement
          ) {
            element.selectElement();
          }
        }
      }, 0);
    }
  }

  scheduleDeselectElements(
    refs: Editor.Styles.ContentStatus['COMMENT'] | Editor.Styles.ContentStatus['TRACKED'] = [],
  ) {
    if (
      refs.length > 0 &&
      this.editorContext.visualizerManager?.getRenderMode() === RENDER_MODES.BASIC
    ) {
      if (this.timeouts.deselectComments) {
        clearTimeout(this.timeouts.deselectComments);
      }

      this.timeouts.deselectComments = setTimeout(() => {
        const query = this.getElementRefQuery(refs.map(({ ref }) => ref));

        const elements = document.querySelectorAll(query);

        for (let i = 0; i < elements.length; i++) {
          const element = elements.item(i);
          if (
            element instanceof CommentElement ||
            element instanceof TemporaryCommentElement ||
            element instanceof TrackDeleteElement ||
            element instanceof TrackInsertElement
          ) {
            element.deselectElement();
          }
        }
      }, 0);
    }
  }

  private scheduleSelectTask(task: Editor.Styles.ContentStatus['TASK']) {
    if (
      typeof task !== 'boolean' &&
      task.blockId != null &&
      this.editorContext.visualizerManager?.getRenderMode() === RENDER_MODES.BASIC
    ) {
      if (this.timeouts.selectTask) {
        clearTimeout(this.timeouts.selectTask);
      }

      this.timeouts.selectTask = setTimeout(() => {
        const viewModel = this.editorContext.visualizerManager?.getViewModelById(task.blockId);

        if (viewModel && viewModel instanceof BlockViewModel) {
          viewModel.setTaskSelected(true);
        }
      }, 0);
    }
  }

  private sheduleDismissTask(task: Editor.Styles.ContentStatus['TASK']) {
    if (typeof task !== 'boolean' && task.blockId != null) {
      if (this.timeouts.dismissTask) {
        clearTimeout(this.timeouts.dismissTask);
      }

      this.timeouts.dismissTask = setTimeout(() => {
        const viewModel = this.editorContext.visualizerManager?.getViewModelById(task.blockId);

        if (viewModel && viewModel instanceof BlockViewModel) {
          viewModel.setTaskSelected(false);
        }
      }, 0);
    }
  }

  private scheduleSetSelectionStatus(status: Editor.Styles.ContentStatus) {
    if (this.timeouts.setSelectionStatus) {
      clearTimeout(this.timeouts.setSelectionStatus);
    }

    this.timeouts.setSelectionStatus = setTimeout(() => {
      ReduxInterface.setSelectionStatus(status);
    }, 0);
  }
}
