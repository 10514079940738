import { EditorDOMUtils } from 'Editor/services/_Common/DOM/EditorDOMUtils';
import { ELEMENTS } from 'Editor/services/consts';

export type NodeDataBuilderOptions = {
  data: Editor.Data.Node.Data;
  rows?: number;
  cells?: number;
  tableWidth?: number;
  rowHeight?: number;
  columnWidth?: number;
};

export class NodeDataBuilder {
  private data: Editor.Data.Node.Data;

  constructor(type?: Editor.Elements.ElementTypesType) {
    this.data = {
      type: type || 'invalid',
    };

    if (this.data.type !== 'text') {
      if (!this.data.id) {
        this.data.id = EditorDOMUtils.generateRandomNodeId();
      }

      if (!this.data.properties) {
        this.data.properties = {};
      }

      if (!this.data.childNodes) {
        this.data.childNodes = [];
      }
    }
  }

  setData(data: Editor.Data.Node.Data) {
    this.data = {
      ...data,
    };

    if (this.data.type !== 'text') {
      if (!this.data.id) {
        this.data.id = EditorDOMUtils.generateRandomNodeId();
      }

      if (!this.data.properties) {
        this.data.properties = {};
      }

      if (!this.data.childNodes) {
        this.data.childNodes = [];
      }
    }
  }

  set id(id: string) {
    this.data.id = id;
  }

  getId() {
    return this.data.id;
  }

  set parentId(parentId: string) {
    this.data.parent_id = parentId;
  }

  set elementType(type: Editor.Elements.ElementTypesType) {
    this.data.type = type;
  }

  set properties(properties: Editor.Data.Node.GenericProperties) {
    if (this.data.type !== 'text') {
      this.data.properties = JSON.parse(JSON.stringify(properties));
    }
  }

  set content(content: string) {
    if (this.data.type === 'text') {
      this.data.content = content;
    }
  }

  getProperties() {
    return this.data.properties;
  }

  set childNodes(childNodes: Editor.Data.Node.Data[]) {
    if (this.data.type !== 'text') {
      this.data.childNodes = JSON.parse(JSON.stringify(childNodes));
    }
  }

  getChild(index: number) {
    return this.data.childNodes?.[index];
  }

  addKeyValue<K extends keyof Editor.Data.Node.Data>(key: K, value: Editor.Data.Node.Data[K]) {
    if (value === 'true') {
      this.data[key] = true;
    } else if (value === 'false') {
      this.data[key] = false;
    } else {
      this.data[key] = value;
    }

    return this;
  }

  addProperty<K extends keyof Editor.Data.Node.GenericProperties>(
    key: K,
    value: Editor.Data.Node.GenericProperties[K],
  ) {
    if (this.data.type !== 'text') {
      if (!this.data.properties) {
        this.data.properties = {};
      }

      if (value === 'true') {
        this.data.properties[key] = true;
      } else if (value === 'false') {
        this.data.properties[key] = false;
      } else {
        this.data.properties[key] = value;
      }
    }

    return this;
  }

  addChildData(childNode?: Editor.Data.Node.Data) {
    if (this.data.type !== 'text') {
      if (!this.data.childNodes) {
        this.data.childNodes = [];
      }

      if (childNode) {
        this.data.childNodes.push(childNode);
      }
    }

    return this;
  }

  addContent(content: Editor.Data.Node.TextData['content']) {
    if (this.data.type === 'text') {
      this.data.content = content;
    }
  }

  normalizeChildNodes() {
    if (this.data.childNodes?.length) {
      const queue = [
        {
          id: this.data.id,
          childNodes: this.data.childNodes,
        },
      ];

      let item;
      while ((item = queue.shift())) {
        for (let i = 0; i < item.childNodes.length; i++) {
          const child = item.childNodes[i];
          if (child.type !== ELEMENTS.Text.ELEMENT_TYPE) {
            child.parent_id = item.id;

            if (!child.id) {
              child.id = EditorDOMUtils.generateRandomNodeId();
            }

            if (child.childNodes?.length) {
              queue.push({
                id: child.id,
                childNodes: child.childNodes,
              });
            }
          }
        }
      }
    }

    return this;
  }

  buildData(): Editor.Data.Node.Data | undefined {
    if (this.data.type && this.data.type !== 'text' && this.data.id) {
      this.normalizeChildNodes();
      return this.data;
    } else if (this.data.type === 'text' && this.data.content) {
      return this.data;
    }

    return undefined;
  }

  private static buildTableRow(options: NodeDataBuilderOptions) {
    const { cells = 3, rowHeight = 48, columnWidth = 36 } = options;

    options.data.type = ELEMENTS.TableElement.ELEMENTS.TABLE_ROW.ELEMENT_TYPE;

    const rowBuilder = new NodeDataBuilder(options.data.type);

    rowBuilder.addProperty('rh', EditorDOMUtils.convertUnitTo(rowHeight, 'px', 'pt', 3));

    for (let i = 0; i < cells; i++) {
      const cellBuilder = new NodeDataBuilder(ELEMENTS.TableCellElement.ELEMENT_TYPE);

      cellBuilder.addKeyValue('parent_id', rowBuilder.getId());

      cellBuilder.addProperty('w', {
        t: 'abs',
        v: columnWidth,
      });

      rowBuilder.addChildData(cellBuilder.buildData());
    }

    return rowBuilder;
  }

  static buildTable(options: NodeDataBuilderOptions) {
    const { rows = 3, cells = 3, tableWidth = 516, rowHeight = 48 } = options;

    options.data.type = ELEMENTS.TableElement.ELEMENT_TYPE;

    const tableBuilder = new NodeDataBuilder(options.data.type);

    if (options.data.parent_id) {
      tableBuilder.addKeyValue('parent_id', options.data.parent_id);
    }

    if (options.data.properties) {
      tableBuilder.properties = options.data.properties;
    }

    const columnWidth = tableWidth / cells;

    if (options.data.properties?.w == null) {
      tableBuilder.addProperty('w', {
        t: 'auto',
        v: 0,
      });
    }

    // cell borders
    if (options.data.properties?.cb == null) {
      tableBuilder.addProperty('cb', {
        t: {
          w: 0.75,
          s: 's',
          c: '000000',
        },
        b: {
          w: 0.75,
          s: 's',
          c: '000000',
        },
        l: {
          w: 0.75,
          s: 's',
          c: '000000',
        },
        r: {
          w: 0.75,
          s: 's',
          c: '000000',
        },
      });
    }

    if (!options.data.childNodes) {
      const bodyBuilder = new NodeDataBuilder(
        ELEMENTS.TableElement.ELEMENTS.TABLE_BODY.ELEMENT_TYPE,
      );

      bodyBuilder.addKeyValue('parent_id', tableBuilder.getId());

      for (let i = 0; i < rows; i++) {
        const rowDataBuilder = NodeDataBuilder.buildTableRow({
          data: {
            type: ELEMENTS.TableElement.ELEMENTS.TABLE_ROW.ELEMENT_TYPE,
          },
          cells,
          rowHeight,
          columnWidth,
        });

        rowDataBuilder.addKeyValue('parent_id', bodyBuilder.getId());

        bodyBuilder.addChildData(rowDataBuilder.buildData());
      }

      tableBuilder.addChildData(bodyBuilder.buildData());
    } else {
      tableBuilder.childNodes = options.data.childNodes;
    }

    return tableBuilder.buildData();
  }

  static buildParagraph(options: NodeDataBuilderOptions) {
    const paragraphBuilder = new NodeDataBuilder(ELEMENTS.ParagraphElement.ELEMENT_TYPE);

    if (options.data.parent_id) {
      paragraphBuilder.addKeyValue('parent_id', options.data.parent_id);
    }

    if (options.data.properties) {
      paragraphBuilder.properties = options.data.properties;
    }

    if (options.data.properties?.s == null) {
      paragraphBuilder.addProperty('s', ELEMENTS.ParagraphElement.BASE_STYLES.PARAGRAPH);
    }

    return paragraphBuilder.buildData();
  }

  static build(data: Editor.Data.Node.Data) {
    const builder = new NodeDataBuilder(data.type);
    builder.setData(data);
    return builder.buildData();
  }
}
