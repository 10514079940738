import { useIntl } from 'react-intl';

import { useDispatch, useSelector } from '_common/hooks';
import { closeAllModals } from '_common/modals/ModalsSlice';

import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';

import {
  CheckInModal,
  ConfirmationModal,
  RenameObjectModal,
  ShareModal,
  UploadTagsModal,
  MoveModal,
  SaveModal,
  TemplateActionModal,
  ExtensionUpdateInformationModal,
  ExtensionErrorsModal,
  AddNewAffiliationFieldModal,
  DifferentTimezoneWarningModal,
  ElementStatusFormModal,
  DeleteElementStatusModal,
  ChangeElementStatusModal,
  EditMetadata,
  MetadataInconsistenciesModal,
  BrowserSupportWarning,
  CreateNewObjectModal,
  SupportFormModal,
  UploadProgressModal,
  CreateGroupModal,
  ChangeAvatarModal,
  ChangeEmailModal,
  ChangeNameModal,
  GenericSettingModal,
  LanguageSettingModal,
  EditTimezoneModal,
  ChangePasswordModal,
  ChangeManagerModal,
  PublicLinkModal,
  CreateNewTagModal,
  NewFolderDocumentModal,
  UserManagementFormModal,
  UploadUsersModal,
  UserTokensModal,
  WOPIRedirectionModal,
  SaveFiltersModal,
  AccessSavedFiltersModal,
  AdvancedSearchModal,
  AboutModal,
  ConvertToModal,
  ExportDocumentModal,
  ExportPDFModal,
} from '_common/modals';
import { PublishVeevaModal } from 'Editor/modals';

const ModalConductor = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const open = useSelector((state) => state.modals.open);
  const firstOpenModal = Object.entries(open).find(([, v]) => v)?.[0] as keyof typeof open;

  return (
    <IntlErrorBoundary
      fallbackType="modal"
      mockProps={{
        title: intl.formatMessage({ id: 'global.error' }),
        onClose: () => dispatch(closeAllModals()),
      }}
      disabled={!firstOpenModal}
    >
      {open.ExportDocumentModal && <ExportDocumentModal />}
      {open.CheckInModal && <CheckInModal />}
      {open.ConfirmationModal && <ConfirmationModal />}
      {open.SaveAsModal && <SaveModal />}
      {open.RenameObjectModal && <RenameObjectModal />}
      {open.UploadTagsModal && <UploadTagsModal />}
      {open.MoveModal && <MoveModal />}
      {open.ShareModal && <ShareModal />}
      {open.AddAffiliationModal && <AddNewAffiliationFieldModal />}
      {open.TemplateActionModal && <TemplateActionModal />}
      {open.ExtensionUpdateInformationModal && <ExtensionUpdateInformationModal />}
      {open.ExtensionErrorsModal && <ExtensionErrorsModal />}
      {open.DifferentTimezoneWarningModal && <DifferentTimezoneWarningModal />}
      {open.ElementStatusFormModal && <ElementStatusFormModal />}
      {open.DeleteElementStatusModal && <DeleteElementStatusModal />}
      {open.ChangeElementStatusModal && <ChangeElementStatusModal />}
      {open.EditMetadata && <EditMetadata />}
      {open.MetadataInconsistenciesModal && <MetadataInconsistenciesModal />}
      {open.PublishVeevaModal && <PublishVeevaModal />}
      {open.BrowserSupportWarningModal && <BrowserSupportWarning />}
      {open.CreateNewObjectModal && <CreateNewObjectModal />}
      {open.SupportFormModal && <SupportFormModal />}
      {open.UploadProgressModal && <UploadProgressModal />}
      {open.CreateGroupModal && <CreateGroupModal />}
      {open.ChangeAvatarModal && <ChangeAvatarModal />}
      {open.ChangeEmailModal && <ChangeEmailModal />}
      {open.ChangeNameModal && <ChangeNameModal />}
      {open.GenericSettingModal && <GenericSettingModal />}
      {open.LanguageSettingModal && <LanguageSettingModal />}
      {open.EditTimezoneModal && <EditTimezoneModal />}
      {open.ChangePasswordModal && <ChangePasswordModal />}
      {open.ChangeManagerModal && <ChangeManagerModal />}
      {open.PublicLinkModal && <PublicLinkModal />}
      {open.CreateNewTagModal && <CreateNewTagModal />}
      {open.NewFolderDocumentModal && <NewFolderDocumentModal />}
      {open.UserManagementFormModal && <UserManagementFormModal />}
      {open.UploadUsersModal && <UploadUsersModal />}
      {open.UserTokensModal && <UserTokensModal />}
      {open.WOPIRedirectionModal && <WOPIRedirectionModal />}
      {open.SaveFiltersModal && <SaveFiltersModal />}
      {open.AccessSavedFiltersModal && <AccessSavedFiltersModal />}
      {open.AdvancedSearchModal && <AdvancedSearchModal />}
      {open.AboutModal && <AboutModal />}
      {open.ConvertToModal && <ConvertToModal />}
      {open.ExportPDFModal && <ExportPDFModal />}
    </IntlErrorBoundary>
  );
};

export default ModalConductor;
